import { Box, Grid, Tooltip, Typography, Chip } from '@mui/material'
import React, { useState } from 'react'
import { StyledIconBox, StyledListRow } from '../../base/commonStyles'
import { useSelector } from 'react-redux'

import Icons from '../Icons'
import Department from '../../assets/icons/DEPARTMENT.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import JOBOFFER from '../../assets/icons/JOBOFFER.svg'
import variables from '../../settings/_variables.scss'
import { Link, useNavigate } from 'react-router-dom'
import { getCompanyIndustries, truncateDetails } from '../../utils/commonFunctions'
import EDITICON from '../../assets/icons/EDIT.svg'
import DeleteIcon from '../../assets/icons/DELETE.svg'
import DeleteEntityBox from '../DeleteEntityBox'
import { useMutation } from '@apollo/client'
import { DELETE_JOB_MUTATION } from '../../queries/jobPostQueries'
import Button from '../Button'
import IconTextBox from '../elements/IconTextBox'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'
import BalancedByBunton from '../../assets/images/balanced_by_bunton.png'

const ProfileJobList = props => {
    const { data, refetch } = props
    const [deleteJob, setDeleteJobModal] = useState(false)

    const navigate = useNavigate()
    const { loginDetails, loginCheckAuthDetails } = useSelector(
        state => state.login
    )

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const { responsiveBreakpoint } = useSelector(state => state.initial)

    const [deleteMutation, { loading: deleteLoading, data: deleteData, error: deleteError }] =
        useMutation(DELETE_JOB_MUTATION, {
            onCompleted(data) {
                refetch()
                setDeleteJobModal(false)
            },
            onError(error) {
                console.log(error, 'error')
            }
        })

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }
    return (
        <StyledListRow maxWidth='xl' disableGutters>
            <Grid container spacing={2}>
                { !data.isActive && data.balancedByBunton && ['admin', 'user'].includes(userRole) && (
                    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                        <Box
                            component="img"
                            src={BalancedByBunton}
                            alt="Balanced By Bunton"
                            sx={{
                                position: "absolute",
                                left: { xs: "48%", sm: "80%", md: "77%", lg: "79%", xl: '80%'},
                                transform: "translate(-50%, -50%)",
                                width: { xs: "53%", sm: "21%", md: "31%", lg: "23%", xl: "16%"},
                                height: "auto",
                                zIndex: 3,
                                marginTop: { xs: "147px", sm: "74px", md: "108px", lg: "73px", xl: '73px'}
                            }}
                            className='balanced-by-bunton'
                        />
                    </Box>
                )}
                { !data.isActive && data.balancedByBunton && ['employer'].includes(userRole) && (
                    <Box
                        component="img"
                        src={BalancedByBunton}
                        alt="Balanced By Bunton"
                        sx={{
                            position: "absolute",
                            left: { xs: "48%", sm: "80%", md: "71%", lg: "79%", xl: '70%'},
                            transform: "translate(-50%, -50%)",
                            width: { xs: "53%", sm: "21%", md: "15%", lg: "12%", xl: "9%"},
                            height: "auto",
                            zIndex: 3,
                            marginTop: { xs: "151px", sm: "73px", md: "7%", lg: "64px", xl: '61px'}
                        }}
                        className='balanced-by-bunton'
                    />
                )}
                <Grid item md={3} xs={12} sm={3} lg={2} xl={1.5} sx={{textAlign:'center'}}>
                    <DefaultCompanyLogo
                        imgSrc={props.showCompanyLogo ? (data.companyLogoUrl ? data.companyLogoUrl : null) : JOBOFFER}
                        sx={{
                            borderRadius: '80px',
                            height: '80px',
                            width: '80px',
                            opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                        }}
                    />
                </Grid>
                <Grid item md={5} xs={12} sm={9} lg={7} xl={7.5}>
                    <Box sx={{opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                        <Typography variant='h5' className='small-size-16px'>
                            {data && data.companyName ? truncateDetails(data.companyName) : '--'}
                        </Typography>
                    </Box>
                    {
                        <Box sx={{opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                            <Link to={(!data.isActive && data.balancedByBunton) ? '' : '/job-details/' + data.id }>
                                <Typography variant='h3' className='strong small-size-18px'>
                                    {data && data.jobTitle ? truncateDetails(data.jobTitle, 30) : '--'}
                                </Typography>
                            </Link>
                        </Box>
                    }
                    <Box
                        sx={{
                            mt: '11px',
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                lg: 'row'
                            },
                            opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                        }}
                    >
                        {data && data.departments && (
                            <IconTextBox
                                iconSrc={Department}
                                text={getCompanyIndustries(data.departments, data.otherDepartment)}
                            />
                        )}
                        {data && data.industries && (
                            <IconTextBox
                                iconSrc={Industry}
                                text={getCompanyIndustries(data.industries, data.otherIndustry)}
                                sx={{
                                    ml: {
                                        xs: 0,
                                        lg: 2
                                    },
                                    mt: {
                                        xs: 1,
                                        lg: 0
                                    }
                                }}
                            />
                        )}
                    </Box>
                </Grid>
                { !data.isActive && !data.balancedByBunton ? (
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        md={3.5}
                        lg={2.5}
                        xl={2.5}
                        sx={{
                            textAlign: {
                                xs: 'center',
                                md: 'right'
                            }
                        }}
                    >
                        <Box sx={{
                            overflow: {
                                xs: 'visible',
                                lg: 'visible',
                            },
                            whiteSpace: {
                                xs: 'normal',
                                lg: 'normal',
                            },
                            wordWrap: {
                                xs: 'break-word',
                                lg: 'break-word',
                            },
                            display: {
                                xs: 'block',
                                lg: 'flex',
                            },
                            alignItems: {
                                xs: 'flex-start',
                                lg: 'center',
                            },
                        }}>
                            <Typography>
                                <Chip
                                    variant='status-chip'
                                    label={<em>{`No longer accepting applications`}</em>}
                                    sx={{
                                        height: { xs: '30px', sm: '30px', md: '30px', lg: '30px', xl: '30px' },
                                        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                                        fontFamily: variables.poppinsRegular,
                                        position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
                                        top: { xs: '-8px', sm: '-8px', md: '-8px', lg: '-7px', xl: '-4px' },
                                        left: { xs: null, sm: null, md: null, lg: '-108px', xl: '-66px' },
                                        padding: { xs: '13px 30px', sm: '13px 30px', md: '0px', lg: '0px', xl: '0px' },
                                    }}
                                    className='shift-right'
                                />
                            </Typography>
                        </Box>
                    </Grid>
                    ): null}
                {['employer'].includes(userRole) && props.isEdit && data.isActive ? (
                    ['mob', 'tab'].includes(responsiveBreakpoint) ? (
                        <Grid
                            container
                            md={12}
                            xs={12}
                            sm={12}
                            justifyContent='center'
                            sx={{
                                mt: 2
                            }}
                        >
                            <Button
                                label='Edit'
                                onClick={() => {
                                    navigate('/edit-job/' + data.id)
                                }}
                                color='primary'
                                variant='contained'
                                size='large'
                                sx={{
                                    padding: '13px 30px',
                                    m: 1
                                }}
                            />
                            <Button
                                label='Delete'
                                onClick={() => {
                                    setDeleteJobModal(true)
                                }}
                                color='secondary'
                                variant='contained'
                                size='large'
                                sx={{
                                    padding: '13px 30px',
                                    m: 1
                                }}
                            />
                        </Grid>
                    ) : data.isActive && (
                        <Grid
                            item
                            md={4}
                            lg={3}
                            xl={3}
                            sx={{ alignItems: 'center', justifyContent: 'end', textAlign: 'end' }}
                            alignSelf='center'>
                            <Button
                                component={Link}
                                to={'/edit-job/' + data.id}
                                sx={{ m: 0, p: 0, minWidth: 'fit-content' }}>
                                <Tooltip title='Edit'>
                                    <StyledIconBox
                                        sx={{
                                            background: '#fff',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Icons
                                            src={EDITICON}
                                            sx={{
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        />
                                    </StyledIconBox>
                                </Tooltip>
                            </Button>
                            <Button
                                sx={{ m: 0, p: 0, minWidth: 'fit-content' }}
                                onClick={() => {
                                    setDeleteJobModal(true)
                                }}>
                                <Tooltip title='Delete'>
                                    <StyledIconBox
                                        sx={{
                                            background: `${variables.ORANGE}`,
                                            ml: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={DeleteIcon}
                                            sx={{ height: '20px' }}
                                        />
                                    </StyledIconBox>
                                </Tooltip>
                            </Button>
                        </Grid>
                    )
                ) : null}
            </Grid>
            {deleteJob && (
                <DeleteEntityBox
                    open={deleteJob}
                    deleteMutation={deleteMutation}
                    entityStatus = {props.data.status}
                    entityId={props.data.id}
                    deleteLoading={deleteLoading}
                    closeDelete={handleDeleteClose}
                />
            )}
        </StyledListRow>
    )
}

export default ProfileJobList
