import React from 'react'
import BaseModal from '../../template/BaseModal'
import { Box } from '@mui/material'
import ModalStatusSection from '../elements/ModalStatusSection'
import SuccessIcon from '../../assets/icons/success_green.svg'
import UploadResumeSection from './UploadResumeSection'
import UploadMotivationalLetter from './UploadMotivationalLetter'
import UploadMotivationalLetterAndResume from './UploadMotivationalLetterAndResume'
import FileUploadModal from '../../template/FileUploadModal'

const ApplyJobSuccessModal = props => {
    const { open, handleClose, message, hasResume, hasMotivationalLetter, candidateId, jobId, handleMotivationalLetterUpload, handleResumeUpload, isMotivationalRequired } = props

    const modalProps = {
        open,
        onClose: handleClose,
        hideClose: false, // Simplified condition
        modalTitle: message,
        boxSx: (hasResume || hasMotivationalLetter) ? { minHeight: '100px', minWidth: '250px', width: '20%' } : {}
    };

    return (
        <>
            {!hasResume && !isMotivationalRequired && (
                <BaseModal
                    open={open}
                    onClose={handleClose}
                    hideClose={(hasResume && !hasMotivationalLetter) ? hasMotivationalLetter : hasResume}
                    modalTitle={
                        ''
                    }
                    boxSx={
                        hasResume || hasMotivationalLetter && {
                            minHeight: '100px',
                            minWidth: '250px',
                            width:'20%'
                        }
                    }
                    >
                            <>
                                <Box>
                                    <ModalStatusSection
                                        statusIcon={SuccessIcon}
                                        statusText={message}
                                        boxSx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: 0
                                        }}
                                        iconSx={
                                            {
                                                height: '45px',
                                                width: '45px',
                                                mb: 0,
                                                mr: 1
                                            }
                                        }
                                        />
                                </Box>
                                <Box sx={{ mt: 4, textAlign: 'center' }}>
                                    <UploadResumeSection modalClose={handleClose}/>
                                </Box>
                            </>
                </BaseModal>
            )}
            {isMotivationalRequired && hasResume && !hasMotivationalLetter && (
                <FileUploadModal {...modalProps}>
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <UploadMotivationalLetter
                            modalClose={handleClose}
                            candidateId={candidateId}
                            jobId={jobId}
                            handleMotivationalLetterUpload={handleMotivationalLetterUpload}
                        />
                    </Box>
                </FileUploadModal>
            )}

            {isMotivationalRequired && !hasResume && !hasMotivationalLetter && (
                <FileUploadModal {...modalProps}>
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <UploadMotivationalLetterAndResume
                            modalClose={handleClose}
                            candidateId={candidateId}
                            jobId={jobId}
                            handleMotivationalLetterUpload={handleMotivationalLetterUpload}
                            handleResumeUpload={handleResumeUpload}
                        />
                    </Box>
                </FileUploadModal>
            )}
        </>
    )
}

export default ApplyJobSuccessModal
