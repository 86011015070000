import { Box, Fade, Grid, Modal, Typography, IconButton } from '@mui/material';
import React from 'react';
import variables from '../settings/_variables.scss';
import { Close } from '@mui/icons-material';
import SuccessIcon from '../assets/icons/success_green.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90vw',  // Mobile
        sm: '80vw',  // Tablet
        md: '60vw',  // Small desktops
        lg: '50vw',  // Large desktops
        xl: '45vw'   // Extra-large desktops
    },
    maxHeight: '90vh',
    maxWidth: {xs: '600px', sm: '600px', md: '600px', lg: '600px', xl: '856px'},
    overflow: 'auto',
    bgcolor: variables.WHITE1,
    border: `1px solid ${variables.BLUE}`,
    boxShadow: 10,
    p: {xs: 1, sm: 1, md: 1, lg: 1, xl: 3},
    borderRadius: 5
};

const FileUploadModal = ({ open, onClose, modalTitle, hideClose, children, titleSx, iconSx }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: variables.WHITEOPACITY,
                backdropFilter: 'blur(2px)',
                zIndex: 9,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    {!hideClose && (
                        <Grid item>
                            <IconButton onClick={onClose} size="small" sx={{ position: 'absolute', top: '23px', right: '23px' }}>
                                <Close sx={{ color: variables.ORANGE, fontSize: '28px' }} />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid 
                            item
                            xs 
                            // sx={{
                            //     display: 'flex', 
                            //     alignItems: 'center', 
                            //     gap: 1.5, // Adjust spacing
                            //     position: 'absolute',
                            //     top: '53px',
                            //     left: '79px',
                            //     ...titleSx
                            // }}
                            display="flex" justifyContent="center" alignItems="center" gap={1} sx={{ mb: 2, position: 'relative', top: {xs: '63px', sm: '63px', md: '63px', lg: '63px', xl: '45px'} }}
                        >
                            <Box
                                component="img"
                                src={SuccessIcon}
                                sx={{
                                    width: 30,
                                    height: 30,
                                    ...iconSx
                                }}
                            />
                            {/* {  modalTitle !== 'Motivationsschreiben aktualisieren' && */}
                            {/* } */}
                            <Typography
                                id="modal-title"
                                sx={{
                                    fontWeight: 800,
                                    fontSize: {xs: "17px", sm: "21px", md: '26px', lg: '27px', xl: '30px'},
                                    fontFamily: 'Poppins ExtraBold',
                                    color: variables.DARK_BLUE
                                }}
                            >
                                {modalTitle}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2, px: 1, pb: 1 }}>
                        {children}
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default FileUploadModal;
