import {
    Backdrop,
    Box,
    CircularProgress,
    Fade,
    Grid,
    Modal,
    ThemeProvider,
    Typography,
    IconButton
} from '@mui/material'
import { Close } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import variables from '../../settings/_variables.scss'
import { Controller, useForm } from 'react-hook-form'
import ModalCloseButton from '../elements/ModalCloseButton'
import Button from '../Button'
import { useMutation } from '@apollo/client'
import { SEND_CUSTOM_MESSAG_MUTATION } from '../../queries/commonQueries'
import { theme } from '../../settings/theme'

import EmailIcon from '@mui/icons-material/Email'
import WYSIWYGEditor from '../WYSIWYGEditor'
import _ from 'lodash'
import ControlledTextInput from '../molecules/ControlledTextInput'
import SelectedUserList from './SelectedUserList'
import { ErrorMessage } from '../../constants'
import { stripHtml } from 'string-strip-html'
import CustomFormLabel from '../elements/CustomFormLabel'
import GroupIcon from '@mui/icons-material/Group';
import InfoModal from './InfoModal'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        sm: '60%',
        md: '60%',
        lg: '50%'
    },
    height: '500px',
    bgcolor: variables.WHITE1,
    boxShadow: 10,
    p: 4,
    borderRadius: '65px'
}

const SendCustomMessageModal = props => {
    const { open, handleClose, checkedBoxes, showToastMessage, clearAllSelected, usersList } = props
    const [isSaving, setIsSaving] = useState(false)
    const [loader, setLoader] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    var keys = Object.keys(checkedBoxes)

    // Functions to open/close the dialog
    const handleDialogOpen = () => setOpenDialog(true);
    const handleDialogClose = () => setOpenDialog(false);

    // var filteredUsers = keys.filter(function (key) {
    //     return checkedBoxes[key]
    // })

    var filteredUsers = Object.values(checkedBoxes).flatMap(obj => Object.keys(obj));

    let initialFormState = {
        subject: '',
        description: ''
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
        register
    } = useForm({
        defaultValues: initialFormState
    })

    const [sendMessageMutation, { loading, data, error }] = useMutation(
        SEND_CUSTOM_MESSAG_MUTATION,
        {
            onCompleted(data) {
                // setIsSaving(false)
                // setLoader(false)
                reset()
                // handleClose()
                clearAllSelected()
                // showToastMessage(
                //     true,
                //     filteredUsers.length > 1
                //         ? 'E-mails sent successfully'
                //         : 'E-mail sent successfully'
                // )
            },
            onError(error) {
                console.log(error, 'error')
            }
        }
    )

    const formSubmit = async data => {
        setIsSaving(true)
        setLoader(true)
        try {
            let formData = {}
            formData = {
                messageSubject: data.subject,
                messageBody: data.description
            }
            sendMessageMutation({
                variables: {
                    userIds: filteredUsers,
                    ...formData
                }
            })
        } catch (err) {
            // setApiError(err)
        }
    }

    const updateStates = () => {
        setLoader(false)
        handleClose()
        setIsSaving(false)
    };

    const newLocal = "107px"
    return (
        <>
            {!loader && <ThemeProvider theme={theme}>
                <Modal
                    open={open}
                    onClose={() => {
                        reset()
                        handleClose()
                    }}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    disableScrollLock={true}
                    slotProps={{
                        backdrop: {
                            sx: {
                                background: variables.CYAN,
                                opacity: '0.7 !important'
                            },
                            timeout: 500
                        }
                    }}
                >
                    <Fade in={open}>
                        <Box
                            sx={{
                                maxHeight: '500px',
                                display: loader ? 'flex' : 'block',
                                ...style
                            }}
                        >
                            <Box sx={{ height: '100%' }}>
                                <Box>
                                    <Grid container spacing={2} alignItems='center'>
                                        <Grid item md={10} sm={10} xs={10} lg={10} xl={10}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <EmailIcon
                                                    sx={{
                                                        color: variables.BLUE,
                                                        fontSize: '40px',
                                                        mr: 2
                                                    }}
                                                />
                                                <Box>
                                                    <Typography
                                                        variant='h2'
                                                        className='modal-heading'
                                                    >
                                                        Send a Message
                                                    </Typography>
                                                    <Typography
                                                        variant='h5'
                                                        className='small-size-16px'
                                                    >
                                                        Please write a message to send to all
                                                        selected users.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={2} sm={2} xs={2}>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="users"
                                                sx={{
                                                    color: 'primary.main',
                                                    fontSize: '20px',
                                                    right: {xs: "0px", sm: "0px", md: "92px", lg: "92px", xl: '107px'},
                                                    float: {xs: "right", sm: "right", md: 'right', lg: "right", xl: 'right'},
                                                    width: 56,
                                                    height: 56,
                                                    borderRadius: '50%',
                                                    backgroundColor: 'rgb(0, 45, 89)',
                                                    padding: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    bottom: {xs: "4px", sm: "4px", md: "4px", lg: "4px", xl: '0px'},
                                                    top: {xs: "-3px", sm: "-3px", md: "35px", lg: "33px", xl: '0px'},
                                                    position: {xs: "relative", sm: "relative", md: "absolute", lg: "absolute", xl: 'relative'},
                                                    '&:hover': {
                                                        backgroundColor: 'rgb(0, 45, 89)',
                                                    },
                                                }}
                                                onClick={handleDialogOpen}
                                            >
                                                <GroupIcon
                                                    sx={{
                                                        color: variables.WHITE,
                                                        fontSize: {
                                                            xs: 30,
                                                            sm: 35,
                                                            md: 40,
                                                            lg: 45,
                                                        },
                                                    }}
                                                />
                                            </IconButton>
                                            {openDialog ? <SelectedUserList open={openDialog} onClose={handleDialogClose} candidateList={usersList} selectedUsersData={checkedBoxes}/> : ""}
                                            <ModalCloseButton
                                                handleClose={() => {
                                                    reset()
                                                    handleClose()
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ my: 4 }}>
                                    <Box>
                                        <Box>
                                            <CustomFormLabel
                                                labelSx={{mt: 3}}
                                                formLabel='Message Subject*'
                                                formFieldError={
                                                    errors &&
                                                    errors.subject &&
                                                    errors.subject.message
                                                }
                                            />

                                            <ControlledTextInput
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            ErrorMessage.customNotificationSubject
                                                    },
                                                    validate: value => {
                                                        return (
                                                            !!value.trim() ||
                                                            ErrorMessage.customNotificationSubject
                                                        )
                                                    }
                                                }}
                                                name='subject'
                                                type='text'
                                                errors={
                                                    errors &&
                                                    errors.subject &&
                                                    errors.subject.message
                                                }
                                                required={true}
                                                fullWidth={true}
                                                formContolSx={{ width: '100%' }}
                                            />
                                        </Box>
                                        <Box>
                                            <CustomFormLabel
                                                labelSx={{mt: 2}}
                                                formLabel='Message Body*'
                                                formFieldError={
                                                    errors &&
                                                    errors.description &&
                                                    errors.description.message
                                                }
                                            />
                                            <Controller
                                                control={control}
                                                rules={{
                                                    validate: {
                                                        required: v =>
                                                            (v && stripHtml(v).result.length > 0) ||
                                                            ErrorMessage.emptyCustomNotificationBody
                                                    }
                                                }}
                                                defaultValue=''
                                                render={({ field }) => (
                                                    <WYSIWYGEditor
                                                        name='description'
                                                        {...field}
                                                        register={register}
                                                        editorStyle={{ maxHeight: '100px' }}
                                                    />
                                                )}
                                                name='description'
                                            />
                                            {/* {errors &&
                                            errors.description &&
                                            errors.description.message ? (
                                                <FormHelperText id='my-helper-text'>
                                                    {errors.description.message}
                                                </FormHelperText>
                                            ) : null} */}
                                        </Box>
                                    </Box>
                                    <Box sx={{ textAlign: 'center', marginTop: {xs: "9px", sm: "9px", md: "37px", lg: "37px", xl: "24px"} }}>
                                        <Button
                                            label={
                                                isSaving ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{ mr: 2 }}
                                                        />{' '}
                                                        Sending E-mail
                                                    </Box>
                                                ) : (
                                                    'Send E-mail'
                                                )
                                            }
                                            color='primary'
                                            variant='contained'
                                            disabled={isSaving || _.isEmpty(checkedBoxes)}
                                            size='large'
                                            sx={{ mr: 2, py: 2, px: 4 }}
                                            onClick={handleSubmit(formSubmit)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </ThemeProvider>}

            {loader && <InfoModal handleClick={updateStates} totalCandidates={filteredUsers} />}
        </>
    )
}

export default SendCustomMessageModal
