import { Box, Chip, CircularProgress, Grid, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { StyledListRow } from '../../../base/commonStyles'
import { Link } from 'react-router-dom'
import variables from '../../../settings/_variables.scss'

import Industry from '../../../assets/icons/INDUSTRY.svg'
import Location from '../../../assets/icons/LOCATION.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'

import Button from '../../Button'
import { useSelector } from 'react-redux'
import Arrow from '../../../assets/icons/ARROW.svg'
import { getCompanyIndustries } from '../../../utils/commonFunctions'
import { getStringFromArray } from '../../../utils/commonFunctions'

import { truncateDetails } from '../../../utils/commonFunctions'
import { useMutation } from '@apollo/client'
import { APPLY_JOB_MUTATION, DELETE_JOB_MUTATION } from '../../../queries/jobPostQueries'
import Icons from '../../Icons'
import EDITICON from '../../../assets/icons/EDIT.svg'
import DeleteIcon from '../../../assets/icons/DELETE.svg'

import StatusBox from '../../StatusBox'
import DeleteEntityBox from '../../DeleteEntityBox'
import ApplyIcon from '../../../assets/icons/ApplyIcon.svg'
import StarIcon from '@mui/icons-material/Star'
import { useTranslation } from 'react-i18next'
import { HomePageButtons } from '../../../pages/HomePage'
import DefaultCompanyLogo from '../../molecules/DefaultCompanyLogo'
import LikeButton from '../../molecules/LikeButton'
import ApplyJobButton from '../../molecules/ApplyJobButton'
import BalancedByBunton from '../../../assets/images/balanced_by_bunton.png'

const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 8px;
`

const JobListRow = props => {
    const { t } = useTranslation()
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [applyLoading, setApplyLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const { data, refetch, userRole } = props
    
    const jobIndustriesString =
        data && data.industries
            ? truncateDetails(getCompanyIndustries(data.industries, data.otherIndustry), 50)
            : ''
    const jobDepartmentString =
        data && data.industries
            ? truncateDetails(getCompanyIndustries(data.departments, data.otherDepartment), 50)
        : ''
    const locationString =
        data && data.states ? truncateDetails(getStringFromArray(data.states), 50) : null

    const [isApplied, setIsApplied] = useState(false)
    const [deleteJob, setDeleteJobModal] = useState(false)
    const [deleteMutation, { loading: deleteLoading, data: deleteData, error: deleteError }] =
        useMutation(DELETE_JOB_MUTATION, {
            onCompleted(data) {
                setDeleteJobModal(false)
                setSuccessMessage('Job Deleted Successfully!')
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    refetch()
                }, 3000)
            },
            onError(error) {
                console.log(error, 'error')
            }
        })

    const [applyJobMutation] = useMutation(APPLY_JOB_MUTATION, {
        onCompleted(data) {
            setSuccessMessage('Applied!')
            setApplyLoading(false)
            setIsSuccess(true)
            setIsApplied(true)

            setTimeout(() => {
                setIsSuccess(false)
            }, 3000)
        }
    })
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const handleApply = () => {
        setApplyLoading(true)
        applyJobMutation({
            variables: {
                jobId: data.id,
                userId: userId
            }
        })
    }

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const actionControlButton = () => {

        return (
            <Box>
                <Box    sx={{
                            display: 'flex',
                            float: { xs: 'none', md: 'right' },
                            justifyContent: { xs: 'center', md: 'right' }
                        }}>
                    { data.isActive ?
                        <HomePageButtons
                            label=''
                            color='primary'
                            variant='contained'
                            size='large'
                            component={Link}
                            to={
                                userRole === 'admin'
                                    ? '/admin-portal/edit-job/' + data.id
                                    : '/edit-job/' + data.id
                            }
                            sx={{
                                background: variables.WHITE,
                                mr: 1,
                                p: `16px !important`,
                                minWidth: '50px',
                                width: '50px',
                                height: '50px',
                                '&:hover': {
                                    background: variables.WHITE
                                }
                            }}>
                            <Box
                                component='img'
                                src={EDITICON}
                                sx={{
                                    height: '30px',
                                    width: '30px'
                                }}
                            />
                        </HomePageButtons>
                    : null}

                    { data.isActive ?
                        <HomePageButtons
                            label=''
                            color='primary'
                            variant='contained'
                            size='large'
                            component={Box}
                            onClick={() => {
                                setDeleteJobModal(true)
                            }}
                            sx={{
                                background: variables.ORANGE,
                                mr: 1,
                                p: `16px !important`,
                                minWidth: '50px',
                                width: '50px',
                                height: '50px',
                                '&:hover': {
                                    background: variables.ORANGE
                                }
                            }}>
                            <Icons
                                src={DeleteIcon}
                                sx={{
                                    height: '20px',
                                    width: '100%'
                                }}
                                style={{
                                    height: '100%',
                                    width: '100%'
                                }}
                            />
                        </HomePageButtons>
                    : null }
                    { !data.isActive && !data.balancedByBunton && (
                        <Box sx={{
                                overflow: {
                                    xs: 'visible',
                                    lg: 'visible',
                                },
                                whiteSpace: {
                                    xs: 'normal',
                                    lg: 'normal',
                                },
                                wordWrap: {
                                    xs: 'break-word',
                                    lg: 'break-word',
                                },
                                display: {
                                    xs: 'block',
                                    lg: 'flex',
                                },
                                alignItems: {
                                    xs: 'flex-start',
                                    lg: 'center',
                                },
                        }}>
                            <Typography>
                                <Chip
                                    variant='status-chip'
                                    label={<em>{`No longer accepting applications`}</em>}
                                    sx={{
                                        height: { xs: '30px', sm: '30px', md: '30px', lg: '30px', xl: '30px' },
                                        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                                        fontFamily: variables.poppinsRegular,
                                        position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
                                        top: { xs: '-6px', sm: '-66px', md: '-44px', lg: '-54px', xl: '-43px' },
                                        left: { xs: null, sm: '-35px', md: '14px', lg: '16px', xl: '17px' },
                                        padding: { xs: null, sm: '0px', md: '0px', lg: '0px', xl: '0px' }
                                    }}
                                    className='shift-right'
                                />
                            </Typography>
                        </Box>
                    )}
                    { !data.isActive && data.balancedByBunton && (
                        <Box
                            component="img"
                            src={BalancedByBunton}
                            alt="Balanced By Bunton"
                            sx={{
                                position: 'relative',
                                height: 'auto',
                                objectFit: 'contain',
                                transform: { xs: 'scale(1.2)', sm: 'scale(1.2)', md: 'scale(1.2)', lg: 'scale(1.2)', xl: 'scale(1.2)' },
                                width: { xs: '63%', sm: '78%', md: '55%', lg: '72%', xl: '70%' },
                                marginTop: { xs: '-323px', sm: 0, md: '-15px', lg: 0, xl: 0 },
                            }}
                            className='balanced-by-bunton'
                        />
                    )}
                    { !data.balancedByBunton && (
                        <HomePageButtons
                            label=''
                            color='primary'
                            variant='contained'
                            size='large'
                            component={(!data.isActive && data.balancedByBunton) ? 'span' : Link}
                            to={!data.isActive && data.balancedByBunton ? '' : '/job-details/' + props.data.id}
                            sx={{
                                background: variables.BLUE,
                                p: `16px !important`,
                                minWidth: '50px',
                                width: '50px',
                                height: '50px',
                                marginRight: { xs: (data.isActive ? null : '-58px'), sm: (data.isActive ? null : '-25px'), md: (data.isActive ? null : '-26px'), lg: null, xl: null },
                                top: {xs: (data.isActive ? null : '31px'), sm: (data.isActive ? null : '27px'), md: (data.isActive ? null : '28px'), lg: null, xl: (data.isActive ? null : '0px')},
                                right: {xs: (data.isActive ? null : '150px'), sm: (data.isActive ? null : '84px'), md: (data.isActive ? null : '36px'), lg: (data.isActive ? null : '34px'), xl: (data.isActive ? null : '32px')},
                                opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)},
                            }}
                        >
                            <Icons
                                src={Arrow}
                                sx={{
                                    height: '20px',
                                    width: '20px'
                                }}
                            />
                        </HomePageButtons>
                    )}
                </Box>
                {['admin'].includes(userRole) && data.noOfCandidatesApplied >
                        0 && data.isActive && ( <Box>
                        <Button
                            label={`${data.noOfCandidatesApplied} Applied`}
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{
                                padding: '8px 30px',
                                width: '100%',
                                mt: 2,
                                color: variables.WHITE
                            }}
                            component={Link}
                            to={
                                '/applied-candidate/' +
                                data.id
                            }
                        />
                    
                </Box>)}
            </Box>
        )
    }

    return (
        <StyledListRow
            maxWidth='xl'
            disableGutters
            sx={{
                opacity: data && data.status === 'draft' ? '0.7': '1',
                minHeight: '160px',
                maxWidth: '100%',
                borderRadius: {
                    xs: '50px',
                    md: '80px'
                }
            }}
        >
            <Grid
                container
                sx={{
                    p: { xs: 1, md: 2 }
                }}
                columnSpacing={{
                    xs: 0,
                    sm: ['admin', 'employer'].includes(userRole) ? 2 : 0,
                    md: 2
                }}
                rowSpacing={{ xs: 2, sm: ['admin', 'employer'].includes(userRole) ? 0 : 2, md: 0 }}
                alignItems='center'
                className='job-details-container'
            >
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 2 : 4}
                    md={['admin', 'employer'].includes(userRole) ? 1.6 : 2}
                    lg={['admin', 'employer'].includes(userRole) ? 1.5 : 1.75}
                    xl={1.5}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: {
                                xs: 'flex',
                                sm: 'block'
                            },
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ width: { xs: '80%', sm: '100%' } }}>
                            <DefaultCompanyLogo
                                imgSrc={data && data.companyLogoUrl ? data.companyLogoUrl : null}
                                sx={{
                                    height: '70px',
                                    width: '70px',
                                    opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                                }}
                            />
                        </Box>
                        {data && data.status === 'published' && data.isActive ? (
                            <Box sx={{ mt: 1 }}>
                                <LikeButton
                                    jobPostId={data.id}
                                    jobPublishedAt={data.publishedAt}
                                    userId={userId}
                                    role={userRole}
                                    totalLikes={data.likes}
                                    isLiked={data.isLiked}
                                    isUserAuthenticated={isUserAuthenticated}
                                    sx={{
                                        width: '70px',
                                    }}
                                />
                            </Box>
                        ) : ""}
                        {data && data.status === 'published' && !data.isActive && data.balancedByBunton ? (
                            <Box sx={{ mt: 1, opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)} }}>
                                <LikeButton
                                    jobPostId={data.id}
                                    jobPublishedAt={data.publishedAt}
                                    userId={userId}
                                    role={userRole}
                                    totalLikes={data.likes}
                                    isLiked={data.isLiked}
                                    sx={{
                                        width: '70px',
                                    }}
                                />
                            </Box>
                        ) : ""}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 6 : 8}
                    md={6.5}
                    lg={7.75}
                    xl={8}
                >
                    <Box>
                        <Typography variant='h5' className='small-size-16px' sx={{ opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                            {data && data.company ? data.company.companyName : ''}
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center',  opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                        <Typography variant='h3' className='strong small-size-18px' sx={{wordBreak: 'break-all'}}>
                            {data && data.jobTitle ? data.jobTitle : ''}
                        </Typography>
                        {data && data.status === 'draft' && (
                            <Chip
                                variant='status-chip'
                                label={data.status.toUpperCase()}
                                sx={{ ml: 1 }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            mt: data && data.status === 'draft' ? 2 : 1,
                            display: {
                                xs: 'block',
                                xl: 'flex'
                            }
                        }}
                    >
                        {
                            jobDepartmentString ? (
                                <Box sx={{ display: 'flex', alignItems: 'start',mt: { xs: 1, xl: 0 },  opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)} }}>
                                    <StyledIcons component='img' src={Department} />
                                    <Typography>{jobDepartmentString}</Typography>
                                </Box>
                            ) : null
                        }
                        {jobIndustriesString ? (
                            <Box sx={{ display: 'flex', alignItems: 'start',mt: { xs: 1, xl: 0 },
                            ml: { xs: 0, xl: 2 },  opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                                <StyledIcons component='img' src={Industry} />
                                <Typography>{jobIndustriesString}</Typography>
                            </Box>
                        ) : null}
                        {locationString ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: { xs: 1, xl: 0 },
                                    ml: { xs: 0, xl: 2 },
                                    alignItems: 'start',
                                    opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                                }}
                            >
                                <StyledIcons component='img' src={Location} />
                                <Typography>{locationString}</Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 4 : 12}
                    md={['admin', 'employer'].includes(userRole) ? 3.9 : 3.5}
                    lg={2.5}
                    xl={2.5}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        }
                    }}
                >
                    {!['admin', 'employer'].includes(userRole) ? (
                        <>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'block',
                                    justifyContent: {
                                        xs: 'center',
                                        sm: 'center',
                                        md: 'right'
                                    }
                                }}
                            >
                                {isUserAuthenticated && userRole === 'user' ? (
                                    <Box
                                        sx={{
                                            mb: '11px'
                                        }}
                                    >
                                        {data.externalLink && userRole === 'user' && data.isActive ? (
                                            <Box>
                                                {data.externalLink ? (
                                                    <Button
                                                        label={
                                                            <Typography sx={{ textAlign: 'center' }}>
                                                                View on
                                                                <br />
                                                                Company website
                                                            </Typography>
                                                        }
                                                        target='_blank'
                                                        color='secondary'
                                                        variant='contained'
                                                        size='large'
                                                        sx={{
                                                            padding: '8px 15px',
                                                            fontSize: '14px',
                                                            fontWeight: '900',
                                                            cursor: 'hand',
                                                            width: '100%',
                                                            maxWidth: '212px'
                                                        }}
                                                        href={
                                                            data.externalLink.indexOf('http://') ===
                                                                0 ||
                                                            data.externalLink.indexOf('https://') ==
                                                                /*  */ 0
                                                                ? data.externalLink
                                                                : `//${data.externalLink}`
                                                        }
                                                    >
                                                        <Typography>
                                                            View on
                                                            <br />
                                                            Company website
                                                        </Typography>
                                                    </Button>
                                                ) : null}
                                            </Box>
                                        ) : data.isActive ? <ApplyJobButton
                                            jobId={data.id}
                                            candidateId={userId}
                                            isMotivationalRequired={data.isMotivationalRequired}
                                            hasCandidateApplied={
                                                data.candidateApplied || isApplied
                                            }
                                        /> : (!data.isActive && data.balancedByBunton) ?  <ApplyJobButton buntonByBuntonIsON={true}/> : ""}
                                    </Box>
                                ) : null}
                                { !data.isActive && !data.balancedByBunton && (
                                    <Box sx={{
                                            overflow: {
                                                xs: 'visible',
                                                lg: 'visible',
                                            },
                                            whiteSpace: {
                                                xs: 'normal',
                                                lg: 'normal',
                                            },
                                            wordWrap: {
                                                xs: 'break-word',
                                                lg: 'break-word',
                                            },
                                            display: {
                                                xs: 'block',
                                                lg: 'flex',
                                            },
                                            alignItems: {
                                                xs: 'flex-start',
                                                lg: 'center',
                                            },
                                    }}>
                                        <Typography>
                                            <Chip
                                                variant='status-chip'
                                                label={<em>{`No longer accepting applications`}</em>}
                                                sx={{
                                                    height: { xs: '30px', sm: '30px', md: '30px', lg: '30px', xl: '30px' },
                                                    width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                                                    fontFamily: variables.poppinsRegular,
                                                    position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
                                                    top: { xs: '-8px', sm: '-8px', md: '-8px', lg: '-20px', xl: '-19px' },
                                                    left: { xs: null, sm: null, md: null, lg: '-106px', xl: '-58px' },
                                                    padding: { xs: '13px 30px', sm: '13px 30px', md: '13px 30px', lg: '0px', xl: '0px' }
                                                }}
                                                className='shift-right'
                                            />
                                        </Typography>
                                    </Box>
                                )}
                                <Box>
                                    <Button
                                        label={
                                            <Typography
                                                variant='h5'
                                                className='small-size-14px extrabold'
                                                sx={{
                                                    color: `${variables.WHITE1} !important`,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {t('buttons.moreinfo')}
                                            </Typography>
                                        }
                                        component={(!data.isActive && data.balancedByBunton) ? 'span' : Link}
                                        to={!data.isActive && data.balancedByBunton ? '' : '/job-details/' + data.id}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            padding: '13px 23px',
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            width: '100%',
                                            opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                                        }}
                                    />
                                </Box>
                            </Box>
                            { !data.isActive && data.balancedByBunton && (
                                <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                                    <Box
                                        component="img"
                                        src={BalancedByBunton}
                                        alt="Balanced By Bunton"
                                        sx={{
                                            position: "absolute",
                                            left: { xs: '49%', sm: '64%', md: '8%', lg: '9%', xl: '10%'},
                                            transform: "translate(-50%, -50%)",
                                            width: { xs: "88%", sm: "45%", md: "105%", lg: "95%", xl: "89%"},
                                            height: "auto",
                                            zIndex: 3,
                                            marginTop: { xs: '-198px', sm: '-153px', md: '-60px', lg: '-51px', xl: '-51px'}
                                        }}
                                        className='balanced-by-bunton'
                                    />
                                </Box>
                            )}
                        </>
                    ) : ['admin', 'employer'].includes(userRole) ? (
                        actionControlButton()
                    ) : null}
                </Grid>
            </Grid>
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityStatus={props.data.status}
                entityId={props.data.id}
                deleteLoading={deleteLoading}
                closeDelete={handleDeleteClose}
            />
        </StyledListRow>
    )
}

export default JobListRow
