import { Box, CircularProgress, FormHelperText, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { DirectUpload } from 'activestorage'
import { useMutation } from '@apollo/client'
import { MANAGE_JOB_POST_MOTIVATIONAL_LETTER_MUTATION } from '../../gql/queries'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '../../constants'
import { StyleFormControl } from '../../base/commonStyles'
import TextInput from '../TextInput'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CustomFormLabel from '../elements/CustomFormLabel'
import variables from '../../settings/_variables.scss'
import { useDispatch } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const uploadFile = (file, mutation, additionalData, fieldName, setUploading, setUploaded, modalClose) => {
    setUploading(true);
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl)

    upload.create((error, blob) => {
        if (error) {
            console.error("Upload Error: ", error)
            setUploading(false);
        } else {
            mutation({
                variables: {
                    ...additionalData,
                    [fieldName]: blob.signed_id
                }
            }).then(() => {
                setUploading(false);
                setUploaded(true);
            });
        }
    })
}

const UploadMotivationalLetter = ({ modalClose, jobId, handleMotivationalLetterUpload }) => {
    const dispatch = useDispatch()
    const hiddenFileInputLetter = useRef(null)

    const [uploadingLetter, setUploadingLetter] = useState(false)
    const [uploadedLetter, setUploadedLetter] = useState(false)
    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
        register
    } = useForm({
        defaultValues: { jobMotivationalLetter: '' }
    })

    const handleFileUpload = (event, mutation, fieldName, setUploading, setUploaded, onChange) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (!validTypes.includes(file.type)) {
            setError(fieldName, {
                type: "manual",
                message: ErrorMessage.motivationalLetterTypeValidation
            });
            event.target.value = '';
            onChange(null);
            return;
        }

        const MAX_FILE_SIZE = 2 * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE) {
            setError(fieldName, {
                type: "manual",
                message: ErrorMessage.motivationalLetterSizeValidation
            });
            event.target.value = '';
            onChange(null);
            return;
        }

        clearErrors(fieldName);
        onChange(file)

        uploadFile(file, mutation, { jobId: jobId }, fieldName, setUploading, setUploaded);
    };

    const [uploadLetterMutation] = useMutation(MANAGE_JOB_POST_MOTIVATIONAL_LETTER_MUTATION, {
        onError: (error) => console.error("Letter Upload Error:", error)
    })

    useEffect(() => {
        if (uploadedLetter) {
            modalClose();
            if(typeof handleMotivationalLetterUpload === "function"){
                handleMotivationalLetterUpload(uploadedLetter)
            }
        } else {
            console.warn("handleMotivationalLetterUpload is not a function", handleMotivationalLetterUpload);
        }
    }, [uploadedLetter, modalClose]);

    return (
        <Box {...register('jobMotivationalLetter')}>
            {/* Motivational Letter Upload */}
            <CustomFormLabel formLabel='Motivationsschreiben hochladen' labelSx={{  float: 'left', position: 'relative', bottom: '11px', left: '-17px' }} styledLabelSx={{fontFamily: variables.poppinsExtraBold}} />
            <Controller
                control={control}
                rules={{ required: ErrorMessage.emptyMotivationalLetter }}
                render={({ field: { onChange, value } }) => (
                    <StyleFormControl>
                        <input
                            type='file'
                            ref={hiddenFileInputLetter}
                            onChange={event => {
                                handleFileUpload(event, uploadLetterMutation, "jobMotivationalLetter", setUploadingLetter, setUploadedLetter, onChange);
                            }}
                            style={{ display: 'none' }}
                            disabled={uploadingLetter || uploadedLetter}
                        />
                        <TextInput
                            name='jobMotivationalLetter'
                            placeholder="Gerät durchsuchen..."
                            value={value ? value.name : ''}
                            disabled={uploadedLetter}
                            formFieldError={
                                errors &&
                                errors.jobMotivationalLetter &&
                                errors.jobMotivationalLetter.message
                            }
                            inputProps={{ readOnly: true, style: { fontSize: '16px', paddingLeft: '23px', fontFamily: variables.poppinsRegular } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {uploadingLetter ? (
                                            <CircularProgress size={20} />
                                        ) : uploadedLetter ? (
                                            <CheckCircleIcon sx={{ color: 'green', fontSize: 20 }} />
                                        ) : (
                                            <IconButton onClick={() => hiddenFileInputLetter.current.click()}>
                                                <ArrowRightAltIcon sx={{
                                                    fontSize: 30,
                                                    strokeWidth: 3,
                                                    color: '#002D59',
                                                    transform: 'scaleX(1.2)',
                                                }} />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                                sx: {
                                    'input::placeholder': {
                                        color: '#9FADB9',
                                        opacity: 0.7,
                                        fontFamily: variables.poppinsRegular
                                    }
                                }
                            }}
                        />
                    </StyleFormControl>
                )}
                name='jobMotivationalLetter'
            />
            {/* {uploadedLetter && <Typography sx={{ color: variables.GREEN }}>Motivational letter uploaded successfully</Typography>} */}
            {errors.jobMotivationalLetter && <FormHelperText error sx={{ textAlign: 'center', fontWeight: 600 }}>{errors.jobMotivationalLetter.message}</FormHelperText>}
            <Typography sx={{marginTop: '31px'}}>
                Sie können die Dateien auch später in Ihrem Dashboard hochladen.
            </Typography>
        </Box>
    )
}

export default UploadMotivationalLetter
