import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import useDrag from '../../utils/useDrag'
import './intrestingjobs.scss'
import { gql, useQuery } from '@apollo/client'

import { LeftArrow, RightArrow } from './arrow'
import { useSelector } from 'react-redux'
import JobPostCard from '../organisams/JobPostCard'
import { useTranslation } from 'react-i18next'
import variables from '../../settings/_variables.scss'
import { Link } from 'react-router-dom'
import { SliderFooterArrows } from '../../utils/commonFunctions'

const INTRESTING_JOBS_QUERY = gql`
    query IntrestingJobs(
            $jobId: String
        ) {
        intrestingJobs(first: 20, jobId: $jobId) {
            nodes {
                id
                jobTitle
                companyName
                companyLogoUrl
                isLiked
                likes
                createdAt
                balancedByBunton
                publishedAt
                states {
                    id
                    name
                }
                departments {
                    id
                    name
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const Arrows = () => {
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                mb: {
                    xs: 3,
                    xl: '50px'
                },
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: '90%',
                    displayPrint: 'block'
                }}
            >
                <Typography variant='h1' className='section-heading'>
                    {t('latestjoboffers.title')}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '10%',
                    justifyContent: 'right',
                    display: {
                        xs: 'none',
                        md: 'flex'
                    },
                    pr: '50px'
                }}
            >
                <Box sx={{ mr: 5 }}>
                    <LeftArrow />
                </Box>
                <Box>
                    <RightArrow />
                </Box>
            </Box>
        </Box>
    )
}

const ViewMoreJobCard = props => {
    const { t } = useTranslation()
    const { isUserAuthenticated } = useSelector(state => state.login)
    return (
        <Link
            to={isUserAuthenticated ? '/job-list' : '/registration'}
            state={{ redirectto: isUserAuthenticated ? null : '/job-list' }}
        >
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    background: variables.CYAN1,
                    borderRadius: '30px',
                    p: 2,
                    minWidth: '290px',
                    width: '290px',
                    height: '252px',
                    minHeight: '252px'
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        m: 'auto',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        verticalAlign: 'middle'
                    }}
                >
                    <Typography
                        variant='h3'
                        className='strong'
                        sx={{ fontSize: '20px !important' }}
                    >
                        {isUserAuthenticated ? 'View All Jobs' : t('latestjoboffers.registertext')}
                    </Typography>
                </Box>
            </Container>
        </Link>
    )
}

const IntrestingJobs = props => {
    const { loading, data, error } = useQuery(INTRESTING_JOBS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            jobId: props.jobId,
            afterCursor: ''
        },
        onCompleted(data) {
        },
        onError(error) {}
    })
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { dragStart, dragStop, dragMove } = useDrag()
    const handleDrag =
        ({ scrollContainer }) =>
        ev =>
            dragMove(ev, posDiff => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff
                }
            })

    if (loading) return ''
    if (error) return `Error! ${error.message}`

    const intrestingJobs = data.intrestingJobs.nodes
    const jobsPageInfo = data.intrestingJobs.pageInfo
    return (
        <>
            {' '}
            {['mob', 'tab'].includes(responsiveBreakpoint) ? (
                <Box component='div' onMouseLeave={dragStop}>
                    <ScrollMenu
                        Header={Arrows}
                        // Footer={SliderFooterArrows}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                    >
                        {intrestingJobs.map((elem, index) => {
                            return <JobPostCard data={elem} index={index} key={index} />
                        })}
                        <ViewMoreJobCard pageInfo={jobsPageInfo} />
                    </ScrollMenu>
                </Box>
            ) : (
                <Box component='div'>
                    <ScrollMenu Header={Arrows}>
                        {intrestingJobs.map((elem, index) => {
                            return <JobPostCard data={elem} index={index} key={index} />
                        })}
                        <ViewMoreJobCard pageInfo={jobsPageInfo} />
                    </ScrollMenu>
                </Box>
            )}
        </>
    )
}

export default IntrestingJobs
