import { Box, Grid, styled, Typography, Chip, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StyledIconBox, StyledListRow } from '../../../base/commonStyles'
import variables from '../../../settings/_variables.scss'
import Industry from '../../../assets/icons/INDUSTRY.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import { useState } from 'react'
import Location from '../../../assets/icons/LOCATION.svg'
import Experience from '../../../assets/icons/EXPERIENCE.svg'
import DeleteEntityBox from '../../../components/DeleteEntityBox'

import {
    getExperienceLabel,
    getStringFromArray
} from '../../../utils/commonFunctions'
import { Button } from '../../../components'
import Arrow from '../../../assets/icons/ARROW.svg'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { DELETE_JOB_MUTATION } from '../../../queries/jobPostQueries'
import Icons from '../../../components/Icons'
import EDITICON from '../../../assets/icons/EDIT.svg'
import StatusBox from '../../../components/StatusBox'
import DeleteIcon from '../../../assets/icons/DELETE.svg'
import DefaultCompanyLogo from '../../../components/molecules/DefaultCompanyLogo'
import { useTranslation } from 'react-i18next'
import BalancedByBunton from '../../../assets/images/balanced_by_bunton.png'
import ApplyJobSuccessModal from '../../../components/molecules/ApplyJobSuccessModal'
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {
    responsiveBreakpoint
} from '../../../redux/actions/initialData'

const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 15px;
`

const DashboardJobsContainer = props => {
    const theme = useTheme()
    const {t} = useTranslation()
    const { jobData, refetch, listType, isResumeUploaded, resumeUploaded, isPreviouslySet, reloadData } = props
    const [isSuccess, setIsSuccess] = useState(false)
    const [deleteJob, setDeleteJobModal] = useState(false)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [showComponent, setShowComponent] = useState(false);
    const [isMotivationalLetterUploaded, setIsMotivationalLetterUploaded] = useState(false);
    const [showMotivationalUpload, setShowMotivationalUpload] = useState(false);
    const [cvUploaded, setCvUploaded] = useState(false);

    useEffect(() => {
        if (jobData?.hasMotivationalLetter) {
            setIsMotivationalLetterUploaded(jobData?.hasMotivationalLetter)
        }
    }, [jobData])

    useEffect(() => {
        if (jobData?.hasResume) {
            if(!resumeUploaded){
                setCvUploaded(true);
            }
        }
    }, [jobData])

    const handleMotivationalLetterUpload = (value) => {
        if(value){
            setIsMotivationalLetterUploaded(value);
            reloadData();
        }
    };

    const handleShowComponent = () => {
        setShowComponent(true);
        isResumeUploaded();
    };

    const handleMotivationalLetterEdit = () => {
        setShowMotivationalUpload(true);
    };

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const handleResumeUpload = (value) => {
        if(value){
            isPreviouslySet(value)
        }
    }

    const [deleteMutation, { loading, data, error }] = useMutation(DELETE_JOB_MUTATION, {
        onCompleted(data) {
            setDeleteJobModal(false)

            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
            }, 3000)

            // refetch({afterCursor:""})
            refetch()
        },
        onError(error) {
            console.log(error, 'error')
        }
    })

    const candidateAppliedJob = jobData?.appliedJobDetails?.find(item => item.job_post_id === Number(jobData.id));
    const motivationalLetterUrl = candidateAppliedJob?.motivational_letter ?? null;

    return (
        <StyledListRow
            maxWidth='xl'
            disableGutters
            sx={{
                minHeight: '160px',
                maxWidth: '100%',
                borderRadius: {
                    xs: '50px',
                    md: '80px'
                }
            }}
        >
            <Grid container sx={{
                    p: { xs: 1, md: 2 }
                }}
                columnSpacing={{
                    xs: 0,
                    sm: props.employDashboard ? 2 : 0,
                    md: 2
                }}
                rowSpacing={{ xs: 2, sm: props.employDashboard ? 0 : 2, md: 0 }}
                alignItems='center'
            >
                <Grid item xs={12}
                    sm={3}
                    md={2}
                    lg={1.75}
                    xl={1.5}
                >
                    <DefaultCompanyLogo
                        imgSrc={jobData && jobData.companyLogoUrl ? jobData.companyLogoUrl : null}
                        sx={{
                            borderRadius: '80px',
                            height: {
                                xs: '80px',
                                sm: '80px',
                                lg: '110px'
                            },
                            width: {
                                xs: '80px',
                                sm: '80px',
                                lg: '110px'
                            },
                            opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6.5}
                    lg={7.75}
                    xl={8}
                >
                    <Box sx={{opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}}}>
                        <Typography
                            variant='h5' className='small-size small-size-16px'
                        >
                            {jobData && jobData.companyName ? jobData.companyName : ''}
                        </Typography>
                    </Box>
                    <Box sx={{opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}}}>
                        <Typography variant='h3' className='strong small-size-18px'>
                            {jobData && jobData.jobTitle ? jobData.jobTitle : ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            display: {
                                xs: 'block',
                                lg: 'flex'
                            }
                        }}
                    >
                        {jobData && jobData.industries ? (
                            <Box sx={{ display: 'flex', alignItems: 'start', opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)} }}>
                                <StyledIcons component='img' src={Industry} />
                                <Typography>
                                    {getStringFromArray(jobData.industries, jobData.otherIndustry)}
                                </Typography>
                            </Box>
                        ) : null}
                        {listType !== 'likedJobs' && jobData && jobData.states ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start',
                                opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}
                            }}>
                                <StyledIcons component='img' src={Location} />
                                <Typography>{getStringFromArray(jobData.states)}</Typography>
                            </Box>
                        ) : null}
                        {jobData && jobData.departments ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start',
                                opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}
                            }}>
                                <StyledIcons component='img' src={Department} />
                                <Typography>
                                    {getStringFromArray(jobData.departments, jobData.otherDepartment)}
                                </Typography>
                            </Box>
                        ) : null}

                        {listType !== 'likedJobs' && jobData && jobData.experienceRequired ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start',
                                opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)}
                            }}>
                                <StyledIcons component='img' src={Experience} />
                                <Typography>
                                    {getExperienceLabel(jobData.experienceRequired)}
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3.5}
                    lg={2.5}
                    xl={2.5}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        }
                    }}
                >
                    {
                        !jobData.isActive && !jobData.balancedByBunton &&
                                <Box sx={{
                                        overflow: {
                                            md: 'visible',
                                            xs: 'visible',
                                            lg: 'visible',
                                            sm: 'visible',
                                        },
                                        whiteSpace: {
                                            md: 'normal',
                                            xs: 'normal',
                                            lg: 'normal',
                                            sm: 'normal',
                                        },
                                        wordWrap: {
                                            md: 'break-word',
                                            xs: 'break-word',
                                            lg: 'break-word',
                                            sm: 'break-word',
                                        },
                                        display: {
                                            md: 'flex',
                                            xs: 'block',
                                            lg: 'flex',
                                            sm: 'flex',
                                        },
                                        alignItems: {
                                            md: 'center',
                                            xs: 'flex-start',
                                            lg: 'center',
                                            sm: 'center',
                                        },
                                }}>
                                    <Typography>
                                        <Chip
                                            variant='status-chip'
                                            label={<em>{`No longer accepting applications`}</em>}
                                            sx={{
                                                height: { xs: '30px', sm: '30px', md: '30px', lg: '30px', xl: '30px' },
                                                width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
                                                fontFamily: variables.poppinsRegular,
                                                position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
                                                top: { xs: '-8px', sm: '-48px', md: '-47px', lg: '-7px', xl: '-12px' },
                                                left: { xs: null, sm: '-146px', md: '-64px', lg: '-101px', xl: '-33px' },
                                                padding: '13px 30px',
                                            }}
                                            className='shift-right'
                                        />
                                    </Typography>
                                </Box>
                    }
                    { !jobData.isActive && jobData.balancedByBunton && (
                        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                            <Box
                                component="img"
                                src={BalancedByBunton}
                                alt="Balanced By Bunton"
                                sx={{
                                    position: "absolute",
                                    left: { xs: "47%", sm: "-7%", md: "18%", lg: "13%", xl: '10%'},
                                    transform: "translate(-50%, -50%)",
                                    width: { xs: "70%", sm: "112%", md: "70%", lg: "72%", xl: "70%"},
                                    height: "auto",
                                    zIndex: 3,
                                    marginTop: { xs: '-124px', sm: '20px', md: '21px', lg: '22px', xl: '23px'}
                                }}
                                className='balanced-by-bunton'
                            />
                        </Box>
                    )}
                    {props.employDashboard ? (
                        <Box
                            sx={{
                                display: 'flex',
                                float: 'right',
                                marginRight: '30px'
                            }}
                        >
                            <Link to={'/edit-job/' + jobData?.id}>
                                <StyledIconBox
                                    sx={{
                                        background: '#fff',
                                        marginLeft: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '5px',
                                            color: variables.BLUE
                                        }}
                                    >
                                        <Icons
                                            src={EDITICON}
                                            sx={{
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        />
                                    </Box>
                                </StyledIconBox>
                            </Link>
                            <StyledIconBox
                                sx={{
                                    background: `${variables.ORANGE}`,
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    setDeleteJobModal(true)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '25%',
                                        color: '#ffffff'
                                    }}
                                >
                                    <Box component='img' src={DeleteIcon} sx={{ height: '20px' }} />
                                </Box>
                            </StyledIconBox>
                            <Link to={'/job-details/' + jobData.id}>
                                <StyledIconBox
                                    sx={{
                                        background: variables.BLUE,
                                        marginLeft: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '25%',
                                            color: '#ffffff'
                                        }}
                                    >
                                        <Box component='img' src={Arrow} />
                                    </Box>
                                </StyledIconBox>
                            </Link>
                        </Box>
                    ) : (
                        <>
                            {jobData.isActive && jobData.isMotivationalRequired && isMotivationalLetterUploaded && !!motivationalLetterUrl ? (
                                <Box
                                    sx={{
                                        marginBottom: '13px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        [theme.breakpoints.only('md')]: {
                                            marginLeft: '-32px'
                                        }
                                    }}
                                >
                                    <Typography
                                        component="a"
                                        href={motivationalLetterUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            backgroundColor: "#bcddd2",
                                            color: "#002D59",
                                            padding: "13px 30px",
                                            borderRadius: "40px",
                                            fontWeight: "bold",
                                            fontFamily: "Poppins ExtraBold",
                                            fontSize: '14px',
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                            marginLeft: responsiveBreakpoint === 'tab' ? '95px' : null
                                        }}
                                    >
                                        Motivationsschreiben
                                    </Typography>

                                    <IconButton
                                        sx={{
                                            padding: '10px',
                                            color: '#002D59',
                                            backgroundColor: '#E69F8C',
                                            borderRadius: '50%',
                                            marginLeft: '10px',
                                            '&:hover': {
                                                backgroundColor: '#E69F8C',
                                            },
                                        }}
                                        onClick={handleMotivationalLetterEdit}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                jobData.isActive && jobData.isMotivationalRequired && listType !== 'likedJobs' && (
                                    <Box
                                        sx={{
                                            marginBottom: '5px',
                                            marginTop: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            component="span"
                                            onClick={handleShowComponent}
                                            sx={{
                                                backgroundColor: "#e69f8c",
                                                color: "#002D59",
                                                borderRadius: "40px",
                                                fontWeight: "bold",
                                                display: "inline-block",
                                                fontFamily: variables.poppinsExtraBold,
                                                fontSize: '14px',
                                                padding: { xs: '13px 16px', xl: "13px 30px" },
                                                whiteSpace: 'nowrap',
                                                textAlign: 'center',
                                                marginBottom: '8px',
                                                marginLeft: responsiveBreakpoint === 'mob' ? '5px' : responsiveBreakpoint === 'tab' ? '67px' : responsiveBreakpoint === 'web' ? '-36px' : null,
                                            }}
                                        >
                                            Motivationsschreiben hochladen
                                        </Typography>
                                    </Box>
                                )
                            )}
                            {jobData.isActive && !jobData.isMotivationalRequired && listType !== 'likedJobs' && (
                                // <Box
                                //     sx={{
                                //         marginBottom: '13px',
                                //         display: 'flex',
                                //         alignItems: 'center',
                                //         justifyContent: 'center',
                                //         [theme.breakpoints.only('md')]: {
                                //             marginLeft: '-32px'
                                //         }
                                //     }}
                                // >
                                    <Typography
                                        component="span"
                                        sx={{
                                            backgroundColor: "#bcddd2",
                                            color: "#002D59",
                                            padding: "13px 30px",
                                            borderRadius: "40px",
                                            fontWeight: "bold",
                                            fontFamily: "Poppins ExtraBold",
                                            fontSize: '14px',
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                            position: 'relative',
                                            top: '-14px',
                                            marginTop: (responsiveBreakpoint === 'mob') ? '15px' : (responsiveBreakpoint === 'tab') ? '15px' : null,
                                            marginLeft: (responsiveBreakpoint === 'tab') ? '75px' : null
                                        }}
                                    >
                                        Bewerbung versendet
                                    </Typography>
                                // </Box>
                            )}
                            <Box>
                                <Button
                                    label={
                                        <Typography
                                            variant='h5'
                                            className='small-size-strong extrabold'
                                            sx={{
                                                color: `${variables.WHITE1} !important`,
                                                fontWeight: 'bold',
                                                opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)},
                                            }}
                                        >
                                            {t('buttons.moreinfo')}
                                        </Typography>
                                    }
                                    component={Link}
                                    to={ jobData.balancedByBunton ? '' : ('/job-details/' + jobData.id)}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        padding: '13px 30px',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        marginLeft: responsiveBreakpoint === 'tab' ? '85px' : null,
                                        opacity: { xs: ((!jobData.isActive && jobData.balancedByBunton) ? 0.6 : null)},
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
            {isSuccess ? <StatusBox top={'3%'} statusText='Job Post Deleted!' /> : null}
            {showComponent && <ApplyJobSuccessModal
                                open={showComponent}
                                handleClose={() => setShowComponent(false)}
                                message='Vielen Dank, für Ihre Bewerbung!'
                                hasResume={cvUploaded || resumeUploaded}
                                hasMotivationalLetter={jobData.hasMotivationalLetter}
                                jobId={jobData.id}
                                handleMotivationalLetterUpload={handleMotivationalLetterUpload}
                                handleResumeUpload={handleResumeUpload}
                                isMotivationalRequired={jobData.isMotivationalRequired}
                            />}
            {showMotivationalUpload && <ApplyJobSuccessModal
                                open={showMotivationalUpload}
                                handleClose={() => setShowMotivationalUpload(false)}
                                message='Vielen Dank, für Ihre Bewerbung!'
                                hasResume={true}
                                hasMotivationalLetter={false}
                                jobId={jobData.id}
                                handleMotivationalLetterUpload={handleMotivationalLetterUpload}
                                handleResumeUpload={handleResumeUpload}
                                isMotivationalRequired={jobData.isMotivationalRequired}
                            />}
            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityStatus={jobData.status}
                entityId={jobData.id}
                deleteLoading={loading}
                closeDelete={handleDeleteClose}
            />
        </StyledListRow>
    )
}

export default DashboardJobsContainer
