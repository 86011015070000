import { Box, CircularProgress, FormHelperText, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import { DirectUpload } from 'activestorage'
import { useMutation } from '@apollo/client'
import { MANAGE_USER_PROFILE_MUTATION, MANAGE_JOB_POST_MOTIVATIONAL_LETTER_MUTATION } from '../../gql/queries'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage, keys } from '../../constants'
import { StyleFormControl } from '../../base/commonStyles'
import TextInput from '../TextInput'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CustomFormLabel from '../elements/CustomFormLabel'
import variables from '../../settings/_variables.scss'
import { useDispatch } from 'react-redux'
import { addHasResume } from '../../redux/actions/profileDetail'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const uploadFile = (file, mutation, additionalData, fieldName, setUploading, setUploaded) => {
    setUploading(true);
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl)

    upload.create((error, blob) => {
        if (error) {
            console.error("Upload Error: ", error)
            setUploading(false);
        } else {
            mutation({
                variables: {
                    ...additionalData,
                    [fieldName]: blob.signed_id
                }
            }).then(() => {
                setUploading(false);
                setUploaded(true);
            });
        }
    })
}

const UploadMotivationalLetterAndResume = ({ modalClose, jobId, handleMotivationalLetterUpload, handleResumeUpload }) => {
    const dispatch = useDispatch()
    const hiddenFileInputResume = useRef(null)
    const hiddenFileInputLetter = useRef(null)

    const [uploadingResume, setUploadingResume] = useState(false)
    const [uploadingLetter, setUploadingLetter] = useState(false)
    const [uploadedResume, setUploadedResume] = useState(false)
    const [uploadedLetter, setUploadedLetter] = useState(false)

    const handleFileUpload = (event, mutation, fieldName, setUploading, setUploaded, onChange) => {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const allowedTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];

        if(fieldName == "jobMotivationalLetter"){
            if (!validTypes.includes(file.type)) {
                setError(fieldName, {
                    type: "manual",
                    message: ErrorMessage.motivationalLetterTypeValidation
                });
                event.target.value = '';
                onChange(null);
                return;
            }
        }
        else{
            if (!allowedTypes.includes(file.type)) {
                setError(fieldName, {
                    type: "manual",
                    message: ErrorMessage.resumeTypeValidation
                });
                event.target.value = '';
                onChange(null);
                return;
            }
        }

        const MAX_FILE_SIZE = 2 * 1024 * 1024;

        if (file.size > MAX_FILE_SIZE) {
            setError(fieldName, {
                type: "manual",
                message: ErrorMessage.motivationalLetterSizeValidation
            });
            event.target.value = '';
            onChange(null);
            return;
        }

        clearErrors(fieldName);
        onChange(file)

        uploadFile(file, mutation, { jobId: jobId }, fieldName, setUploading, setUploaded);
    };

    const {
        control,
        formState: { errors },
        setError,
        clearErrors,
        register
    } = useForm({
        defaultValues: { candidateResume: '', jobMotivationalLetter: '' }
    })

    const [uploadResumeMutation] = useMutation(MANAGE_USER_PROFILE_MUTATION, {
        onCompleted: () => {
            dispatch(addHasResume({ key: keys.uploadResume, data: true }))
            // modalClose()
        },
        onError: (error) => console.error("Resume Upload Error:", error)
    })

    const [uploadLetterMutation] = useMutation(MANAGE_JOB_POST_MOTIVATIONAL_LETTER_MUTATION, {
        onError: (error) => console.error("Letter Upload Error:", error)
    })

    useEffect(() => {
        if(uploadedResume && !uploadedLetter){
            if(typeof handleResumeUpload === "function"){
                handleResumeUpload(true);
            }
        }
        if (uploadedResume && uploadedLetter) {
            modalClose();
        }
        if(uploadedLetter && typeof handleMotivationalLetterUpload === "function"){
            handleMotivationalLetterUpload(uploadedLetter);
        }
    }, [uploadedResume, uploadedLetter, modalClose]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxHeight: '80vh', marginTop: '0px' }}>
            <Box {...register('jobMotivationalLetter')}>
                {/* Motivational Letter Upload */}
                <CustomFormLabel
                    formLabel='Motivationsschreiben hochladen'
                    labelSx={{
                        float: 'left',
                        position: 'relative',
                        bottom: '11px',
                        left: '-17px',
                        fontFamily: 'Poppins ExtraBold',
                    }}

                    styledLabelSx={{fontFamily: variables.poppinsExtraBold}}
                />
                <Controller
                    control={control}
                    rules={{ required: ErrorMessage.emptyMotivationalLetter }}
                    render={({ field: { onChange, value } }) => (
                        <StyleFormControl>
                            <input
                                type='file'
                                ref={hiddenFileInputLetter}
                                onChange={event => {
                                    handleFileUpload(event, uploadLetterMutation, "jobMotivationalLetter", setUploadingLetter, setUploadedLetter, onChange);
                                }}
                                style={{ display: 'none' }}
                                disabled={uploadingLetter || uploadedLetter}
                            />
                            <TextInput
                                name='jobMotivationalLetter'
                                placeholder="Gerät durchsuchen..."
                                value={value ? value.name : ''}
                                disabled={uploadedLetter}
                                formFieldError={
                                    errors &&
                                    errors.jobMotivationalLetter &&
                                    errors.jobMotivationalLetter.message
                                }
                                inputProps={{
                                    readOnly: true,
                                    style: {
                                        fontSize: '16px',
                                        paddingLeft: '23px',
                                        fontFamily: 'Poppins Regular'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                        {uploadingLetter ? (
                                            <CircularProgress size={20} />
                                        ) : uploadedLetter ? (
                                            <CheckCircleIcon sx={{ color: 'green', fontSize: 20 }} />
                                        ) : (
                                            <IconButton onClick={() => hiddenFileInputLetter.current.click()}>
                                            <ArrowRightAltIcon sx={{
                                                fontSize: 30,
                                                strokeWidth: 3,
                                                color: '#002D59',
                                                transform: 'scaleX(1.2)',
                                            }} />
                                            </IconButton>
                                        )}
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        'input::placeholder': {
                                            color: '#9FADB9',
                                            opacity: 0.7,
                                            fontFamily: variables.poppinsRegular
                                        }
                                    }
                                }}
                            />
                        </StyleFormControl>
                    )}
                    name='jobMotivationalLetter'
                />
                {errors.jobMotivationalLetter && <FormHelperText error sx={{ textAlign: 'center', fontWeight: 600 }}>{errors.jobMotivationalLetter.message}</FormHelperText>}
            </Box>

            <Box {...register('candidateResume')}>
                {/* Resume Upload */}
                <CustomFormLabel
                    formLabel='Lebenslauf hochladen'
                    labelSx={{
                        float: 'left',
                        position: 'relative',
                        bottom: '19px',
                        left: '-17px',
                        fontFamily: 'Poppins ExtraBold'
                    }}
                    styledLabelSx={{fontFamily: variables.poppinsExtraBold}}
                />
                <Typography
                    variant='h5'
                    className='small-size'
                    sx={{
                        my: 1,
                        marginLeft: '13px',
                        marginTop: '-23px',
                        marginBottom: '30px',
                        textAlign: "left",
                        display: "block",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        position: 'relative',
                        fontSize: {xs: '16px !important', sm: '17px !important', md: '18px !important', lg: '18px !important', xl: '20px !important'},
                        color: '#002D59',
                        float: 'left'
                    }}
                >
                    Durch das Hochladen Ihres Lebenslaufs ermöglichen Sie uns, Ihre Fähigkeiten und Erfahrungen optimal mit den passenden Positionen zu matchen.
                </Typography>
                <Controller
                    control={control}
                    rules={{ required: ErrorMessage.emptyResume }}
                    render={({ field: { onChange, value } }) => (
                        <StyleFormControl>
                            <input
                                type='file'
                                ref={hiddenFileInputResume}
                                onChange={event => {
                                    handleFileUpload(event, uploadResumeMutation, "candidateResume", setUploadingResume, setUploadedResume, onChange);
                                }}
                                style={{ display: 'none' }}
                                disabled={uploadingResume || uploadedResume}
                            />
                            <TextInput
                                name='candidateResume'
                                placeholder="Gerät durchsuchen..."
                                value={value ? value.name : ''}
                                disabled={uploadedResume}
                                formFieldError={
                                    errors &&
                                    errors.candidateResume &&
                                    errors.candidateResume.message
                                }
                                inputProps={{
                                    readOnly: true,
                                    style: {
                                        fontSize: '16px',
                                        paddingLeft: '23px',
                                        fontFamily: 'Poppins Regular'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {uploadingResume ? (
                                                <CircularProgress size={20} />
                                            ) : uploadedResume ? (
                                                <CheckCircleIcon sx={{ color: 'green', fontSize: 20 }} />
                                            ) : (
                                                <IconButton onClick={() => hiddenFileInputResume.current.click()}>
                                                <ArrowRightAltIcon sx={{
                                                    fontSize: 30,
                                                    strokeWidth: 3,
                                                    color: '#002D59',
                                                    transform: 'scaleX(1.2)',
                                                }} />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        'input::placeholder': {
                                        color: '#9FADB9',
                                        opacity: 0.7,
                                        fontFamily: variables.poppinsRegular
                                        }
                                    }
                                }}
                            />
                        </StyleFormControl>
                    )}
                    name='candidateResume'
                />
                {errors.candidateResume && <FormHelperText error sx={{ textAlign: 'center', fontWeight: 600 }}>{errors.candidateResume.message}</FormHelperText>}
            </Box>

            <Typography sx={{marginTop: '31px', fontFamily: variables.poppinsRegular, fontSize: {xs: '15px !important', sm: '15px !important', md: '16px !important', lg: '16px !important', xl: '18px !important'},}}>
                Sie können die Dateien auch später in Ihrem Dashboard hochladen.
            </Typography>
        </Box>
    )
}

export default UploadMotivationalLetterAndResume
