import { gql } from '@apollo/client'

export const JOB_LIST_QUERY = gql`
    query JobListings(
        $first: Int
        $afterCursor: String
        $applicants: Boolean
        $keyword: String
        $industryIds: String
        $departmentIds: String
        $locationIds: String
        $experience: String
        $companyUserId: String
        $jobType: String
    ) {
        jobsListings(
            first: $first
            after: $afterCursor
            applicants: $applicants
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
            companyUserId: $companyUserId
            jobType: $jobType
        ) {
            edges {
                node {
                    id
                    jobTitle
                    experienceRequired
                    candidateApplied
                    companyLogoUrl
                    externalLink
                    likes
                    isLiked
                    status
                    noOfCandidatesApplied
                    createdAt
                    publishedAt
                    isActive
                    balancedByBunton
                    company {
                        id
                        companyName
                        noOfEmployees
                    }
                    otherDepartment
                    otherIndustry
                    appliedJobDetails
                    departments {
                        id
                        name
                    }
                    industries {
                        id
                        name
                    }
                    states {
                        id
                        name
                    }
                    candidates {
                        id
                        userId
                        name
                        title
                        experience
                        industries {
                            id
                            name
                        }
                        departments {
                            id
                            name
                        }
                        user {
                            profileImage
                        }
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
        companiesCount
        getJobCounts(
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
            companyUserId: $companyUserId
            jobType: $jobType
        )
    }
`
