import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button, IconButton, useMediaQuery, useTheme, Backdrop, Fade } from "@mui/material";
import PopUpSurvey from '../../../assets/images/popUpSurvey.png'
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

const SurveyModal = () => {
    const { loginCheckAuthDetails, loginDetails } = useSelector(state => state.login)
    const [open, setOpen] = useState(false);
    const {t} = useTranslation()
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const encryptData = (data) => {
        const textEncoder = new TextEncoder();
        const encodedData = textEncoder.encode(data);
        return window.btoa(String.fromCharCode(...new Uint8Array(encodedData)));
    };

    useEffect(() => {
        const encryptedId = encryptData(loginCheckAuthDetails.id.toString());
        const modalKey = `hasSeenSurveyModal_${encryptedId}`;
        const hasSeenModal = localStorage.getItem(modalKey);

        if (!hasSeenModal) {
            setOpen(true);
            localStorage.setItem(`hasSeenSurveyModal_${encryptedId}`, "true");
        }
    }, []);

    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="survey-modal"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                sx: {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                },
            }}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "60%", sm: "60%", md: "60%", lg: "60%", xl: "28%" },
                        maxWidth: 700,
                        backgroundColor: "#fffaf4",
                        borderRadius: 5,
                        boxShadow: 24,
                        p: 4,
                        textAlign: "center",
                        outline: "2px solid #003A5D",
                        height: "auto",
                    }}
                >   <IconButton
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            top: 15,
                            right: 25,
                            color: "#E69F8C",
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            fontWeight: '900',
                        }}
                    >
                        <CloseIcon sx={{ fontSize: { xs: 28, sm: 28, md: 28, lg: 28, xl: 35 } }} />
                    </IconButton>
                    <Box>
                        <Box
                            component="img"
                            src={PopUpSurvey}
                            alt='Pop-up Survey'
                            sx={{
                                width: { xs: 100, sm: 100, md: 100, lg: 100, xl: 100 },
                                height: "auto",
                                maxWidth: "100%"
                            }}
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "bold",
                            color: "#003A5D",
                            fontSize: { xs: "16px", sm: "18px", md: "24px" },
                            marginTop: '23px',
                            fontWeight: '900',
                            fontFamily: 'Poppins ExtraBold',
                            letterSpacing: "0.3px",
                            opacity: 1,
                        }}
                    >
                        Was braucht es, damit wir Gleichheit {isDesktop && <br />} in Top Executive Positionen erreichen?
                    </Typography>

                    <Typography sx={{ mt: 2, color: "#002D59", fontSize: { xs: "14px", sm: "15px" }, letterSpacing: '0.32px' }}>
                        Teilen Sie Ihre Meinung mit uns & helfen Sie bunton dabei, {isDesktop && <br />} <i>Balance in the boardrooms</i> zu bringen.
                    </Typography>

                    <Typography sx={{ mt: 2, color: "#002D59", fontWeight: "bold", fontSize: { xs: "14px", sm: "15px" }, letterSpacing: '0.32px', fontFamily: 'Poppins ExtraBold' }}>
                        KOSTENLOS, SICHER & ANONYM
                    </Typography>

                    <Typography sx={{ mt: 1, color: "#002D59", fontSize: { xs: "12px", sm: "15px" }, letterSpacing: '0.32px' }}>
                        In Zusammenarbeit mit der Hochschule für angewandte {isDesktop && <br />} Wissenschaften München & dem F.A.Z. Institut.
                    </Typography>

                    <Button
                        color='secondary'
                        variant='contained'
                        size='large'
                        href='https://docs.google.com/forms/d/e/1FAIpQLSd2W6MT67INXOIf2LFDXVNdZBHBKAkUKfQ5XJWkTyldLN1_Mw/viewform'
                        target="_blank"
                        className='strong'
                        sx={{
                            padding: '10px 30px',
                            marginTop: '30px'
                        }}
                        onClick={handleClose}
                    >
                        <Typography
                            variant='h3'
                            className='strong'
                            sx={{
                                ml: 1,
                                fontSize: '16px'
                            }}
                        >
                            {t('onlinesurvey')}
                        </Typography>
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
};

export default SurveyModal;
