import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { experiencesList } from '../../utils/constantData'
import { Box, Chip, Container, FormControl, Grid, IconButton, Typography, Tooltip } from '@mui/material'
import TextInput from '../TextInput'
import FindJobIcon from '../../assets/icons/SEARCH_BLUE.svg'
import Location from '../../assets/icons/LOCATION.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import Department from '../../assets/icons/DEPARTMENT.svg'
import Experience from '../../assets/icons/EXPERIENCE.svg'
import MultiSelectDropDown from '../elements/MultiSelectDropDown'
import variables from '../../settings/_variables.scss'
import { StyledStatusChip } from '../../base/commonStyles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { X } from 'react-feather'

const ListFilters = props => {
    const { redirectTo, hideJobType, queryParams } = props
    const { t } = useTranslation()

    const { responsiveBreakpoint, allJobTypes } = useSelector(state => state.initial)
    const location = useLocation()
    const state = location.state
    const { allStates, allIndustries, allDepartments } = useSelector(state => state.initial)
    let allIndustriesData = JSON.parse(JSON.stringify(allIndustries))

    let allStatesData = JSON.parse(JSON.stringify(allStates))

    let allDepartmentsData = JSON.parse(JSON.stringify(allDepartments))

    let experiencesListData = JSON.parse(JSON.stringify(experiencesList))

    const [jobType, setJobType] = useState(
        state && state.jobType && state.jobType !== '' ? state.jobType : []
    )
    const [searchKeyword, setSearchKeyword] = useState(
        queryParams.keyword ? queryParams.keyword : ''
    )
    const [locationIds, setLocationIds] = useState(
        queryParams.locationIds?.length > 0 ? queryParams.locationIds?.split(',') : []
    )
    const [industryIds, setIndustryIds] = useState(
        queryParams.industryIds?.length > 0 ? queryParams.industryIds?.split(',') : []
    )
    const [departmentIds, setDepartmentIds] = useState(
        queryParams.departmentIds?.length > 0 ? queryParams.departmentIds?.split(',') : []
    )
    const [experience, setExperience] = useState(
        queryParams.experience?.length > 0 ? queryParams.experience?.split(',') : []
    )

    const navigate = useNavigate()

    const filterList = () => {
        let queryParams = {}

        if (searchKeyword) {
            queryParams = { ...queryParams, keyword: searchKeyword }
        }

        if (locationIds.length > -1) {
            queryParams = { ...queryParams, locationIds: locationIds.join(',') }
        }

        if (industryIds.length > -1) {
            queryParams = { ...queryParams, industryIds: industryIds.join(',') }
        }

        if (departmentIds.length > -1) {
            queryParams = { ...queryParams, departmentIds: departmentIds.join(',') }
        }

        if (experience.length > -1) {
            queryParams = { ...queryParams, experience: experience.join(',') }
        }

        if (queryParams) props.fetchData(btoa(JSON.stringify(queryParams)))
    }

    const responsivePath = ['/', '/dashboard'].includes(location.pathname)

    useEffect(() => {
        const listener = event => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                filterList()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [searchKeyword])

    return (
        <Container
            maxWidth='lg'
            disableGutters={{ xs: true, sm: false }}
            sx={{
                my: {
                    xs: '20px',
                    sm: '30px',
                    md: '50px'
                }
            }}
        >
            <Box
                component='div'
                sx={{
                    margin: '0 auto'
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={responsivePath ? 10 : 12} sm={12} md={8} lg={8}>
                        <FormControl
                            sx={{
                                width: '100%'
                            }}
                        >
                            <TextInput
                                type='text'
                                value={searchKeyword}
                                name='searchKeyword'
                                placeholder='Search User by name'
                                formFieldError=''
                                required={true}
                                onChange={e => {
                                    setSearchKeyword(e.target.value)
                                }}
                                fullWidth={true}
                                sx={{
                                    width: '100%',
                                    fontSize: '18px',
                                    padding: '0px'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <Box
                                            component='img'
                                            src={FindJobIcon}
                                            sx={{
                                                height: '18px',
                                                width: '18px',
                                                ml: 1,
                                                mt: {
                                                    xs: 0,
                                                    sm: '-3px'
                                                }
                                            }}
                                        />
                                    )
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {!['mob', 'tab'].includes(responsiveBreakpoint) ? (
                        <Grid
                            item
                            xs={
                                ['mob', 'tab'].includes(responsiveBreakpoint) && responsivePath
                                    ? 10
                                    : 12
                            }
                            sm={5}
                            md={4}
                            lg={4}
                        >
                            <MultiSelectDropDown
                                startAdornment={<Box component='img' src={Location} />}
                                placeholder={t('selectlocation')}
                                formControllerSx={{
                                    width: '100%'
                                }}
                                lab={t('location')}
                                options={allStatesData}
                                onChange={e => {
                                    setLocationIds(e.target.value)
                                }}
                                value={locationIds}
                            />
                        </Grid>
                    ) : null}
                </Grid>
                {['mob', 'tab'].includes(responsiveBreakpoint) && responsivePath ? null : (
                    <Grid container columns={10} spacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={10} sm={5} md={3} lg={3}>
                            <MultiSelectDropDown
                                startAdornment={<Box component='img' src={Industry} />}
                                placeholder={t('selectindustry')}
                                lab={t('industry')}
                                options={allIndustriesData}
                                onChange={e => {
                                    setIndustryIds(e.target.value)
                                }}
                                value={industryIds}
                                formControllerSx={{
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={10} sm={5} md={3} lg={3}>
                            <MultiSelectDropDown
                                startAdornment={<Box component='img' src={Department} />}
                                placeholder={t('selectdepartment')}
                                lab={t('department')}
                                options={allDepartmentsData}
                                onChange={e => {
                                    setDepartmentIds(e.target.value)
                                }}
                                value={departmentIds}
                                formControllerSx={{
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={10} sm={5} md={3} lg={3}>
                            <MultiSelectDropDown
                                startAdornment={<Box component='img' src={Experience} />}
                                placeholder={t('selectexperience')}
                                lab={t('experience')}
                                options={experiencesListData}
                                onChange={e => {
                                    setExperience(e.target.value)
                                }}
                                value={experience}
                                formControllerSx={{
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={10} sm={4} md={1} lg={1} textAlign='right'>
                            <IconButton
                                color='primary'
                                variant='contained'
                                size='large'
                                onClick={() => filterList()}
                                sx={{
                                    background: variables.CYAN,
                                    p: 2,
                                    width: '100%',
                                    borderRadius: '30px',
                                    '&:hover': {
                                        background: variables.CYAN
                                    }
                                }}
                            >
                                <Box
                                    component='img'
                                    src={FindJobIcon}
                                    sx={{ height: '30px', width: '30px' }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                {location.pathname === '/' ? null : ['mob', 'tab'].includes(
                      responsiveBreakpoint
                  ) ? null : hideJobType ? null : (
                    <Grid
                        container
                        sx={{
                            marginTop: '15px'
                        }}
                    >
                        {allJobTypes.map(type => (
                            <StyledStatusChip
                                label={
                                    jobType === type.id ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckCircleIcon sx={{ width: '20px', mr: 1 }} />
                                            <Typography>{type.name}</Typography>
                                        </Box>
                                    ) : (
                                        <Typography>{type.name}</Typography>
                                    )
                                }
                                variant='outlined'
                                sx={{
                                    fontSize: '15px',
                                    marginRight: '15px',
                                    mt: '10px'
                                }}
                                onClick={e => {
                                    // e.preventDefault()
                                    if (jobType === type.id) {
                                        setJobType(null)
                                        navigate(redirectTo, { state: { ...{}, dashboard: false } })
                                    } else {
                                        setJobType(type.id)
                                        navigate(redirectTo, {
                                            state: { ...{ jobType: type.id }, dashboard: false }
                                        })
                                    }
                                }}
                            />
                        ))}
                    </Grid>
                )}
            </Box>
            {queryParams &&
                (queryParams.keyword ||
                    queryParams.departmentIds ||
                    queryParams.experience ||
                    queryParams.industryIds ||
                    queryParams.locationIds) && (
                    <Grid container alignItems='center' sx={{ mt: 3 }}>
                        <Grid item md={8} sm={9} xs={9}>
                            {queryParams.keyword && (
                                <Chip
                                    variant='selected-filter'
                                    icon={<X color={variables.BLUE} />}
                                    label={queryParams.keyword}
                                    onClick={() => {
                                        let newQueryParams = {}
                                        setSearchKeyword(null)
                                        newQueryParams = { ...queryParams, keyword: '' }
                                        if (newQueryParams)
                                            props.fetchData(btoa(JSON.stringify(newQueryParams)))
                                    }}
                                />
                            )}
                            {queryParams.locationIds && (() => {
                                const locationIdsArray = queryParams.locationIds.split(',');
                                const matchedNames = allStates
                                    .filter(location => locationIdsArray.includes(location.id))
                                    .map(location => location.name)
                                    .join(', ');

                                return (
                                    <Tooltip title={matchedNames || 'No Locations'} arrow>
                                        <Chip
                                            variant='selected-filter'
                                            icon={<X color={variables.BLUE} />}
                                            label={
                                                'Locations'
                                            }
                                            onClick={() => {
                                                let newQueryParams = {}
                                                setLocationIds(null)
                                                newQueryParams = { ...queryParams, locationIds: '' }
                                                if (newQueryParams)
                                                    props.fetchData(btoa(JSON.stringify(newQueryParams)))
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })()}
                            {queryParams.industryIds && (() => {
                                const industryIdsArray = queryParams.industryIds.split(',');
                                const matchedNames = allIndustries
                                    .filter(industry => industryIdsArray.includes(industry.id))
                                    .map(industry => industry.name)
                                    .join(', ');

                                return (
                                    <Tooltip title={matchedNames || 'No Industries'} arrow>
                                        <Chip
                                        variant='selected-filter'
                                        icon={<X color={variables.BLUE} />}
                                        label={
                                            'Industries'
                                        }
                                        onClick={() => {
                                            let newQueryParams = {}
                                            setIndustryIds([])
                                            newQueryParams = { ...queryParams, industryIds: '' }
                                            if (newQueryParams)
                                                props.fetchData(btoa(JSON.stringify(newQueryParams)))
                                        }}
                                    />
                                    </Tooltip>
                                );
                            })()}
                            {queryParams.departmentIds && (() => {
                                const departmentIdsArray = queryParams.departmentIds.split(',');
                                const matchedNames = allDepartments
                                    .filter(dept => departmentIdsArray.includes(dept.id))
                                    .map(dept => dept.name)
                                    .join(', ');

                                return (
                                    <Tooltip title={matchedNames || 'No Departments'} arrow>
                                        <Chip
                                            variant="selected-filter"
                                            icon={<X color={variables.BLUE} />}
                                            label="Departments"
                                            onClick={() => {
                                                let newQueryParams = {};
                                                setDepartmentIds([]);
                                                newQueryParams = { ...queryParams, departmentIds: '' };
                                                if (newQueryParams) props.fetchData(btoa(JSON.stringify(newQueryParams)));
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })()}
                            {queryParams.experience && (
                                <Tooltip title={queryParams.experience.replace(/,/g, ", ")  || 'No Experiences'} arrow>
                                    <Chip
                                        variant='selected-filter'
                                        icon={<X color={variables.BLUE} />}
                                        label={
                                            'Experiences'
                                        }
                                        onClick={() => {
                                            let newQueryParams = {}
                                            setExperience(null)
                                            newQueryParams = { ...queryParams, experience: '' }
                                            if (newQueryParams)
                                                props.fetchData(btoa(JSON.stringify(newQueryParams)))
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                        {queryParams &&
                            (queryParams.keyword ||
                                queryParams.departmentIds ||
                                queryParams.experience ||
                                queryParams.industryIds ||
                                queryParams.locationIds) && (
                                <Grid
                                    item
                                    xs={responsiveBreakpoint === 'mob' ? 12 : 3}
                                    md={4}
                                    sm={12}
                                    textAlign={
                                        responsiveBreakpoint === 'mob' ||
                                        responsiveBreakpoint === 'tab'
                                            ? 'left'
                                            : 'right'
                                    }
                                >
                                    <Chip
                                        variant='selected-filter'
                                        icon={<X color='red' />}
                                        label={'Clear all filters'}
                                        onClick={() => {
                                            let newQueryParams = {}
                                            setSearchKeyword('')
                                            setIndustryIds('')
                                            setLocationIds('')
                                            setDepartmentIds('')
                                            setExperience('')
                                            newQueryParams = {
                                                locationIds: '',
                                                industryIds: '',
                                                departmentIds: '',
                                                keyword: '',
                                                experience: ''
                                            }
                                            if (newQueryParams)
                                                props.fetchData(btoa(JSON.stringify(newQueryParams)))
                                        }}
                                    />
                                </Grid>
                            )}
                    </Grid>
                )}
        </Container>
    )
}

export default ListFilters
