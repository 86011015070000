import { Box, LinearProgress, linearProgressClasses, styled, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import Button from '../Button'
import { Link } from 'react-router-dom'
import Icons from '../Icons'
import Arrow from '../../assets/icons/ARROW_RIGHT.svg'
import { useSelector } from 'react-redux'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    borderRadius: 25,
    border: `3px solid ${variables.CYAN}`,
    backgroundColor: variables.WHITE1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: variables.WHITE1
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 20,
        backgroundColor: variables.CYAN
    }
}))

const ProgressBarLabel = styled(Typography)`
    width: 200px;
    height: 100%;
    z-index: 1;
    max-height: 30px;
    text-align: left;
    align-items: center;
    color: ${variables.BLUE};
    & span: {
        width: 100%;
    }
`

const ProfileCompletionLoader = props => {

    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <Box
            sx={{
                maxWidth: {
                    xs: '100%',
                    md: '363px'
                },
                width: '100%',
                position: 'relative',
                height: {
                    xs: '45px',
                    md: '33px'
                }
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '5px',
                width: '100%',
                textAlign: 'left',
                zIndex: 5,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                ml: 2
                }}
            >
                <ProgressBarLabel >
                    Profile <strong> {props.progress}% </strong> completed
                </ProgressBarLabel>
            </Box>
            <Box sx={{display: 'flex', alignItems:'left', height: '100%'}}>
                <BorderLinearProgress variant='determinate' value={props.progress} />
                {   responsiveBreakpoint === 'mob' ? 
                    <Button
                        label=''
                        component={Link}
                        to={props.link}
                        color='secondary'
                        variant='contained'
                        sx={{ml: 2, height: '51px', width: '51px',minWidth: '51px', p:2}}
                    >
                        <Icons src={Arrow} height={20}/>
                    </Button> : null
                }
            </Box>
        </Box>
    )
}

export default ProfileCompletionLoader
