export const TextStrings = {
    login: 'Login',
    loginNew: 'or login with e-mail below',
    email: 'E-Mail',
    password: 'Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',

    confirmPassword: 'Repeat New Password',
    forgotPassword: 'Forgot Password?',
    setNewPassword: 'Set up new password',
    changePassword: 'Change Password',

    resetPassword: 'Reset Password',
    loginWithLinkedin: 'Login with Linkedin',
    loginWithGoogle: 'Login with Google',
    signIn: 'Sign in',
    dontHaveAcc: 'You don’t have a bunton account yet? Go to ',
    signUp: 'Sign Up',
    signUNew: 'or sign up with e-mail',
    forgotPasswordInst: (
        <>Please submit your Email Address to receive instructions for resetting your password.</>
    ),
    start: 'Start',
    submit: 'Submit',
    or: 'or',
    registrationComplete: 'Your registration is complete.',
    checkInbox: 'Please check your inbox and enter the 5-count verification code.',
    sendCodeAgain: 'Send code again',
    signUpWithLinkedin: <>Register with Linkedin</>,
    signUpWithGoogle: 'Register with Google',
    signUpWithEmail: 'Register with E-mail',
    alreadyHaveAccount: 'You already have a bunton account? Go to ',
    welcome: 'Welcome!',
    newUserWelcome: 'Welcome to bunton!',
    welcomeText: (
        <>
            <p>
                Bunton brings balance to the boardroom by helping diversify the management teams of
                corporations by including diverse leaders.
            </p>{' '}
            <p>Become a part of the network today!</p>
        </>
    ),
    termsPolicy: 'Accept Terms of use & Privacy policy',
    terms: 'Terms of use',
    policy: 'Privacy policy',
    startBy: 'Start by completing your profile.',
    complete: 'complete',
    education: 'Education',
    workExperience: 'Work Experience',
    uploadResume: 'Upload Resume',
    languages: 'Languages',
    selectAnAvatar: 'Select an Avatar',
    add: 'Add',
    setUpLater: 'set up later',
    save: 'Save',
    select: 'Select',
    edit: 'Edit',
    saveChanges: 'Save changes',
    delete: 'Delete',
    youAreReady: 'You are ready',
    goToProfile: 'Go to profile',
    aPosition: 'a position',
    currentlyWorking: 'Currently working here.',
    additionalInfos: 'Additional Infos',
    addJobTitle: <>Job title must be minimum 3 characters, and can include <b>-:,/()&!”*;\äöüß</b>.</>
}

export const ErrorMessage = {
    emptyEmail: 'Please enter your email.',
    validEmail: 'Please enter a valid email',
    emptyPassword: 'Please enter your password.',
    validPassword: 'Length must be 6 or more',
    terms: 'Kindly accept terms & policy',
    emptyCertificateDegreeName: 'Please enter graduation degree.',
    emptyYearOfCompletion: 'Please select year of completion.',
    emptyStartYear: 'Please select start year',
    emptyStartMonth: 'Please select start month',
    emptyEndMonth: 'Please select end month',
    emptyEndYear: 'Please select end year',
    endYearMax: 'end year should not be less than start year',
    dateError: 'End date should be more than start date',
    upcomingDateError: 'Date should not be future date',
    emptyJobTitle: 'Please enter a valid job title.',
    emptyCompany: 'Please enter company name',
    emptyDepartment: 'Please select department',
    emptyIndustry: 'Please select industry',
    emptyCompanyName: 'Please enter your company name.',
    emptyCompanyEmail: 'Please enter your company email.',
    emptyWebsiteLink: 'Please enter website link.',
    validWebsiteLink: 'Please enter a valid website link',
    emptyEmployee: 'Please select number of employee.',
    emptyLocation: 'Please select your location.',
    emptyLanguage: 'Please select your language.',
    emptyIndustries: 'Please select industry.',
    emptyContactPerson: 'Please enter name',
    emptyJobType: 'Please select job type',
    emptyBenefits: 'Please select benefits',
    emptyExperience: 'Please select experience',
    emptyJobDescription: 'Please enter job description',
    emptyJobLink: 'Please enter external job link',
    validJobLink: 'Please enter a valid job link',
    emptySalaryRange: 'Please select salary range',
    emptyCompanyId: 'Please select company',
    emptyContactUsEmail: 'Please enter your email.',
    emptyContactUsName: 'Please enter your name.',
    emptyName: 'Please enter your name.',
    emptyProfession: 'Please enter your profession.',
    emptyProfileDescription: 'Please enter description',
    emptyCompanyLogo: 'Please select logo',
    logoSizeValidation: 'Please upload a file smaller than 1 MB.',
    resumeSizeValidation: 'Please upload a file smaller than 2 MB.',
    motivationalLetterSizeValidation: 'Please upload a file smaller than 2 MB.',
    logoTypeValidation: 'Only PNG, JPEG or JPG files are allowed.',
    resumeTypeValidation: 'Only PFD, PNG, JPEG or JPG files are allowed.',
    motivationalLetterTypeValidation: 'Only PDF or Word files are allowed.',
    emptyResume: 'Please upload resume',
    emptyMotivationalLetter: 'Please motivational letter',
    emptyAvatar: 'Please select avatar',
    emptyMajor: 'Please enter subject.',
    emptyOthers: 'Please specify others.',
    shareEmptyName: 'Please enter person name.',
    validLinkedInUrl: 'Please enter a valid LinkedIn Profile URL.',
    companyType: 'Please specify registration type',
    customNotificationSubject: 'Please enter subject for the notification',
    emptyCustomNotificationBody: 'Please enter message body',
    regMaxNumberOfIndustry: 'Only MAX_NUM industries can be selected.',
    regMaxNumberOfDepartment: 'Only MAX_NUM departments can be selected.',
    regMaxNumberOfLocation: 'Only MAX_NUM locations can be selected.',
    jobDescriptionReviewerName: 'Please enter reviewer name.',
    jobDescriptionReviewerEmail: 'Please enter reviewer email.',
    emptyBusinessNetworkMember: 'Please specify your membership status.',
    emptyBusinessNetwork: 'Please select business network(s).',
    regMaxNumberOfBusinessNetwork: 'Only MAX_NUM business network can be selected.',
}

export const keys = {
    education: 'edu',
    workExperience: 'exp',
    languages: 'lang',
    selectAnAvatar: 'avatar',
    additionalInfos: 'additionalInfos',
    uploadResume:'uploadResume',
    uploadMotivationalLetter:'uploadMotivationalLetter'
}

export const Regex = {
    emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    websiteRegex:
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
    linkedInRegex:
        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm,
    linkedInCompanyPageRegex:
        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(company)\/([-a-zA-Z0-9]+)\/*/gm
}

export const Placeholder = {
    enterEmail: 'Enter email',
    enterPassword: 'Enter password',
    newPassword: 'New Password',
    currentPassword: 'Current Password',
    othersField: 'Specify Others',
    confirmPassword: 'Repeat New Password',
    instituteName: 'E.g. Frankfurt University',
    certificateDegreeName: 'E.g. Bachelors degree',
    major: 'E.g. Business Administration',
    search: 'Search',
    jobTitle: 'E.g. Senior Software Developer',
    companyName: 'E.g. Apple Inc.',
    contactUsMessage: 'Enter your message'
}

export const FormLabels = {
    instituteName: 'University or training institution*',
    certificateDegreeName: 'Graduation degree*',
    major: 'Subject*',
    yearOfCompletion: 'Year of completion*',
    startDate: 'Start date*',
    endDate: 'End date*',
    jobTitle: 'Job title*',
    companyName: 'Company*',
    departmentId: 'Department*',
    industryId: 'Industry*'
}
