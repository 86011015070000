import { gql } from '@apollo/client'

export const MANAGE_EDUCATION_MUTATION = gql`
    mutation ManageEducation(
        $id: ID
        $major: String
        $certificateDegreeName: String
        $yearOfCompletion: String
        $actionType: String!
    ) {
        manageEducation(
            id: $id
            major: $major
            certificateDegreeName: $certificateDegreeName
            yearOfCompletion: $yearOfCompletion
            actionType: $actionType
        ) {
            educationDetails {
                id
                certificateDegreeName
                major
                yearOfCompletion
                isCurrentlyPursuing
            }
            actionType
            id
        }
    }
`

export const MANAGE_EXPERIENCE_MUTATION = gql`
    mutation ManageExperience(
        $id: ID
        $jobTitle: String
        $companyName: String
        $departmentId: ID
        $industryId: ID
        $isCurrentJob: Boolean
        $startDate: String
        $endDate: String
        $actionType: String!
        $otherDepartment: String
        $otherIndustry: String
    ) {
        manageExperience(
            id: $id
            jobTitle: $jobTitle
            companyName: $companyName
            departmentId: $departmentId
            industryId: $industryId
            isCurrentJob: $isCurrentJob
            startDate: $startDate
            endDate: $endDate
            otherIndustry: $otherIndustry
            otherDepartment: $otherDepartment
            actionType: $actionType
        ) {
            experienceDetails {
                id
                jobTitle
                startDate
                endDate
                departmentId
                companyName
                industryId
                otherDepartment
                otherIndustry
                department {
                    id
                    name
                }
                industry {
                    id
                    name
                }
                isCurrentJob
            }
            actionType
            id
        }
    }
`

export const DELETE_USER_MUTATION = gql`
    mutation DeleteUser($id: ID!) {
        deleteUser(id: $id) {
            message
        }
    }
`

export const MANAGE_USER_PROFILE_MUTATION = gql`
    mutation ManageUserProfile(
        $name: String
        $title: String
        $experience: String
        $location: String
        $language: String
        $industryIds: String
        $otherIndustry: String
        $departmentId: String
        $otherDepartment: String
        $otherBenefits: String
        $about: String
        $benefitIds: String
        $profileImage: String
        $candidateResume: String
        $actionType: String
        $linkedinUrl: String
        $professionalNetworkMember: String
        $professionalNetwork: String
        $otherNetworks: String
    ) {
        manageUserProfile(
            name: $name
            title: $title
            experience: $experience
            location: $location
            language: $language
            industryIds: $industryIds
            otherIndustry: $otherIndustry
            departmentId: $departmentId
            otherDepartment: $otherDepartment
            otherBenefits: $otherBenefits
            about: $about
            benefitIds: $benefitIds
            profileImage: $profileImage
            candidateResume: $candidateResume
            actionType: $actionType
            linkedinUrl: $linkedinUrl
            professionalNetworkMember: $professionalNetworkMember
            professionalNetwork: $professionalNetwork
            otherNetworks:$otherNetworks
        ) {
            user {
                profileImage
                candidate {
                    id
                    title
                    language
                    location
                    experience
                    about
                    candidateResumeLink
                    hasResume
                    professionalNetworkMember
                    otherNetworks
                    businessNetworks{
                        id
                        name
                    }
                    benefits {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const MANAGE_USER_PROFILE_IMAGE_MUTATION = gql`
    mutation ManageProfileImage($profileImage: String, $actionType: String) {
        manageProfileImage(profileImage: $profileImage, actionType: $actionType) {
            user {
                id
                profileImage
            }
            actionType
        }
    }
`

export const MANAGE_JOB_POST_MOTIVATIONAL_LETTER_MUTATION = gql`
    mutation ManageJobPostMotivationalLetter($jobId: String, $jobMotivationalLetter: String) {
        uploadJobPostMotivationalLetter(jobId: $jobId, jobMotivationalLetter: $jobMotivationalLetter) {
            success
            errors
        }
    }
`

export const LOGIN_MUTATION = gql`
    mutation SignIn(
        $email: String
        $password: String
        $provider: String
        $accessToken: String
        $name: String
        $industry: String
        $department: String
        $experience: String
        $location: String
        $profession: String
        $loginLocation: String!
        $referrerSite: String
        $businessNetwork: String
        $otherDepartment: String
        $otherBusinessNetwork: String
        $otherIndustry: String
    ) {
        signIn(
            email: $email
            password: $password
            provider: $provider
            name: $name
            accessToken: $accessToken
            industry: $industry
            department: $department
            experience: $experience
            location: $location
            profession: $profession
            loginLocation: $loginLocation
            referrerSite: $referrerSite
            businessNetwork: $businessNetwork
            otherDepartment: $otherDepartment
            otherBusinessNetwork: $otherBusinessNetwork
            otherIndustry: $otherIndustry
        ) {
            accessToken
            client
            expiry
            id
            uid
            firstName
            profileImage
            lastName
            name
            role
            lastLogoutAt
            userRoleId
            hasAnswered
            currentUser {
                id
                name
                firstName
                lastName
                name
                email
                lastLogoutAt
                userRole
                mobileNumber
                profileImage
                hasAnswered
                candidate {
                    id
                    language
                    hasResume
                }
                company {
                    id
                    companyLogoUrl
                }
            }
        }
    }
`

export const UPDATE_USER_LAST_QUESTION_ASKED = gql`
    mutation UpdateUserLastQuestionAsked($userId: ID!) {
        updateUserLastQuestionAsked(userId: $userId)
    }
`
