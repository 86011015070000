import { Box, CircularProgress, Container, Grid, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../../settings/_variables.scss'
import { getStringFromArray, truncateDetails } from '../../../utils/commonFunctions'
import { Link } from 'react-router-dom'
import Industry from '../../../assets/icons/INDUSTRY.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import LocationIcon from '../../../assets/icons/LOCATION.svg'

import Button from '../../Button'
import { useMutation } from '@apollo/client'
import { APPLY_JOB_MUTATION, INVITE_RESPONSE_MUTATION } from '../../../queries/jobPostQueries'
import { useSelector } from 'react-redux'
import DefaultCompanyLogo from '../../molecules/DefaultCompanyLogo'
import { useTranslation } from 'react-i18next'
import LikeButton from '../../molecules/LikeButton'
import BalancedByBunton from '../../../assets/images/balanced_by_bunton.png'

const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 15px;
`

const PendingInvitationRow = props => {
    const { t } = useTranslation()
    const { setIsSuccess, setSuccessMessage, data } = props
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const [response, setResponse] = useState('')
    const [isApplied, setIsApplied] = useState(false)
    const [acceptLoader, setAcceptLoader] = useState(false)
    const [declineLoader, setDeclineLoader] = useState(false)
    const [responseMutation, { loading, data: mutationData, error }] = useMutation(
        INVITE_RESPONSE_MUTATION,
        {
            onCompleted(data) {
                setSuccessMessage(
                    response === 'accept'
                        ? 'Invitation Accepted'
                        : response === 'decline'
                        ? 'Invitation Declined'
                        : ''
                )

                props.refetchAppliedJobCount()
                setIsSuccess(true)
                setAcceptLoader(false)
                setDeclineLoader(false)
                setTimeout(() => {
                    setIsSuccess(false)
                    props.refetch()
                }, 3000)
            },
            onError(error) {
                console.log(error, 'error')
            }
        }
    )

    const [applyJobMutation] = useMutation(APPLY_JOB_MUTATION, {
        onCompleted(data) {
            setSuccessMessage('Applied!')
            props.refetchAppliedJobCount()
            setAcceptLoader(false)
            setDeclineLoader(false)
            setIsSuccess(true)
            setIsApplied(true)

            setTimeout(() => {
                setIsSuccess(false)
                props.refetch()
            }, 3000)
        }
    })

    // const [isSuccess, setIsSuccess] = useState(false)
    // const [successMessage, setSuccessMessage] = useState('')
    return (
        <Container
            key={props.index}
            maxWidth={false}
            disableGutters
            sx={{
                position: "relative",
                background: variables.CYAN1,
                borderRadius: '50px',
                p: '20px',
                mr: { xs: 0, sm: 2 },
                mb: { xs: 2, sm: 0 },
                minWidth: '300px',
                width: {
                    xs: '100%',
                    sm: '332px'
                },
                minHeight: '337px'
            }}
        >
            {!data.isActive && data.balancedByBunton && (
                <Box
                    component="img"
                    src={BalancedByBunton}
                    alt="Balanced By Bunton"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "48%", sm: "56%", md: "50%", lg: "55%", xl: "57%"},
                        height: "auto",
                        zIndex: 3,
                    }}
                />
            )}
            <Box sx={{ position: "relative", zIndex: 3 }}>
                <Grid container columns={2}>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                        <DefaultCompanyLogo
                            imgSrc={data && data.companyLogoUrl ? data.companyLogoUrl : null}
                            sx={{
                                width: '75px',
                                height: '75px',
                                borderRadius: '70px',
                                margin: '0 auto',
                                opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                            }}
                        />
                    </Grid>
                    { data.isActive && (
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={{textAlign: 'right'}}>
                            <LikeButton
                                jobPostId={data.id}
                                jobPublishedAt={data.publishedAt}
                                userId={userId}
                                role={userRole}
                                totalLikes={data.likes}
                                isLiked={data.isLiked}
                                refetchAppliedJobCount={props.refetchAppliedJobCount}
                                isUserAuthenticated={isUserAuthenticated}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box>
                <Box sx={{opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}}}>
                    <Typography variant='h5' className='small-size-16px'>
                        {truncateDetails(data && data.companyName ? data.companyName : '', 27)}
                    </Typography>
                    <Typography variant='h3' className='small-size-18px strong' sx={{ mt: 1 }}>
                        {truncateDetails(data && data.jobTitle ? data.jobTitle : '', 27)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 2
                    }}
                >
                    {data && data.industries ? (
                        <Box sx={{ display: 'flex', opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)} }}>
                            <StyledIcons component='img' src={Industry} />
                            <Typography>
                                {truncateDetails(getStringFromArray(data.industries, data.otherIndustry), 27)}
                            </Typography>
                        </Box>
                    ) : null}
                    {data && data.states ? (
                        <Box
                            sx={{
                                mt: 1,
                                display: 'flex',
                                opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                            }}
                        >
                            <StyledIcons component='img' src={LocationIcon} />
                            <Typography>{truncateDetails(getStringFromArray(data.states), 27)}</Typography>
                        </Box>
                    ) : null}
                    {data && data.departments ? (
                        <Box
                            sx={{
                                mt: 1,
                                display: 'flex',
                                opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                            }}
                        >
                            <StyledIcons component='img' src={Department} />
                            <Typography>
                                {truncateDetails(getStringFromArray(data.departments, data.otherDepartment), 27)}
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <Box sx={{ mt: 3, width: '100%' }}>
                { !data.balancedByBunton &&
                    <Button
                        label={
                            <Typography
                                variant='h5'
                                className='small-size-strong extrabold'
                                sx={{
                                    color: `${variables.WHITE1} !important`,
                                    fontWeight: 'bold'
                                }}
                            >
                                {t('buttons.moreinfo')}
                            </Typography>
                        }
                        component={Link}
                        to={'/job-details/' + data.id}
                        color='primary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 30px',
                            width: '100%'
                        }}
                    />
                }
                { data.isActive && (
                    <Box
                        sx={{
                            mt: 3,
                            display: 'flex'
                        }}>
                        <Button
                            label={
                                acceptLoader ? (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: variables.BLUE
                                        }}
                                    />
                                ) : (
                                    t('buttons.accept')
                                )
                            }
                            disabled={acceptLoader || declineLoader}
                            color='secondary'
                            variant='inviteButtons'
                            size='large'
                            sx={{
                                padding: '10px 30px',
                                fontWeight: '600',
                                color: variables.GREEN,
                                textTransform: 'none',
                                mr: 1,
                                width: '50%'
                            }}
                            onClick={() => {
                                setAcceptLoader(true)
                                applyJobMutation({
                                    variables: {
                                        jobId: data.id,
                                        userId: userId
                                    }
                                })
                            }}
                        />
                        <Button
                            label={
                                declineLoader ? (
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: variables.BLUE
                                        }}
                                    />
                                ) : (
                                    t('buttons.decline')
                                )
                            }
                            disabled={acceptLoader || declineLoader}
                            color='secondary'
                            variant='inviteButtons'
                            size='large'
                            sx={{
                                ml: 1,
                                fontWeight: '600',
                                padding: '10px 30px',
                                color: variables.SOFTORANGE,
                                textTransform: 'none',
                                width: '50%'
                            }}
                            onClick={() => {
                                setDeclineLoader(true)
                                setResponse('decline')
                                responseMutation({
                                    variables: {
                                        jobId: data.id,
                                        response: 'decline'
                                    }
                                })
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Container>
    )
}

export default PendingInvitationRow
