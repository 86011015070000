import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { getStringFromArray, truncateDetails } from '../../utils/commonFunctions'
import { Link } from 'react-router-dom'
import DefaultCompanyLogo from '../molecules/DefaultCompanyLogo'
import Location from '../../assets/icons/LOCATION.svg'
import { StyledIcons } from '../../base/commonStyles'
import Department from '../../assets/icons/DEPARTMENT.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LikeButton from '../molecules/LikeButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BalancedByBunton from '../../assets/images/balanced_by_bunton.png'

const JobPostCard = props => {
    const { t } = useTranslation()
    const { data, index, orientation } = props
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    return <Box sx={{
        background: variables.CYAN1,
        borderRadius: '30px',
        p: 2,
        mr: 2,
        mb: 2,
        minWidth: '290px',
        width: '290px',
        height: '220px',
        minHeight: '220px'
    }}>
        <Grid container>
            { !data.isActive && data.balancedByBunton && (
                <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                    <Box
                        component="img"
                        src={BalancedByBunton}
                        alt="Balanced By Bunton"
                        sx={{
                            position: "absolute",
                            left: { xs: "47%", sm: "49%", md: "50%", lg: "50%", xl: '46%'},
                            transform: "translate(-50%, -50%)",
                            width: { xs: "70%", sm: "67%", md: "70%", lg: "72%", xl: "70%"},
                            height: "auto",
                            zIndex: 3,
                            marginTop: { xs: '115px', sm: '109px', md: '108px', lg: '110px', xl: '114px'}
                        }}
                        className='balanced-by-bunton'
                    />
                </Box>
            )}
            <Grid item md={3} xs={3} sm={3} xl={3} lg={3}>
                <DefaultCompanyLogo
                    imgSrc={data.companyLogoUrl}
                    sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '70px',
                        margin: '0 auto',
                        border: `2px solid ${variables.WHITE1}`,
                        opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)}
                    }}
                />
            </Grid>
            <Grid item md={9} xs={9} xl={9} lg={9} sm={9} sx={{ textAlign: 'right', opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)} }}>
                <Box sx={{display:'flex', justifyContent:'end'}}>
                    <LikeButton
                        jobPostId={data.id}
                        jobPublishedAt={data.publishedAt}
                        userId={userId}
                        role={userRole}
                        totalLikes={data.likes}
                        isLiked={data.isLiked}
                        isUserAuthenticated={isUserAuthenticated}
                        refetchAppliedJobCount={props.refetchAppliedJobCount}
                    />
                    <Button
                        label={''}
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to={!data.isActive && data.balancedByBunton ? '' : '/job-details/' + data.id}
                        sx={{
                            height: '50px',
                            width: '50px',
                            minWidth:'50px',
                            p: 1,
                            ml: 1
                        }}
                    >
                        <ArrowForwardIcon sx={{fontSize: '18px', stroke: variables.WHITE,
                                    strokeWidth: 1}}/>
                    </Button>
                </Box>
            </Grid>
        </Grid>
        <Box sx={{ maxHeight: '118px', height: '100%', mt: 1, opacity: { xs: ((!data.isActive && data.balancedByBunton) ? 0.6 : null)} }}>
            <Box sx={{minHeight: '50px'}}>
                <Typography variant='h5' className='small-size-15px blue-color font-family-poppins-regular'>
                    {truncateDetails(data.companyName, 20)}
                </Typography>
                <Typography variant='h3' className='small-size-17px strong' sx={{ mt: 1 }}>
                    {truncateDetails(data.jobTitle, 20)}
                </Typography>
            </Box>
            <Box >
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <StyledIcons component='img' src={Location} />
                    <Typography variant='h5' className='small-size-15px blue-color font-family-poppins-regular'>
                        {truncateDetails(getStringFromArray(data.states), 20)}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <StyledIcons component='img' src={Department} />
                    <Typography variant='h5' className='small-size-15px blue-color font-family-poppins-regular'>
                        {truncateDetails(getStringFromArray(data.departments), 20)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    </Box>
    return (
        <>
            <Container
                key={index}
                maxWidth={false}
                disableGutters
                sx={{
                    background: variables.CYAN1,
                    borderRadius: '50px',
                    p: '20px',
                    mr: { xs: orientation === 'vertical' ? 0 : 2, sm: 2 },
                    mb: { xs: orientation === 'vertical' ? 2 : 0, sm: 0 },
                    minWidth: '300px',
                    width: {
                        xs: orientation === 'vertical' ? '100%' : '300px',
                        sm: '332px'
                    },
                    height: '337px',
                    minHeight: '337px'
                }}
            >
                <Grid container columns={2}>
                    <Grid item md={1} xs={1}>
                        <DefaultCompanyLogo
                            imgSrc={data.companyLogoUrl}
                            sx={{
                                width: '75px',
                                height: '75px',
                                borderRadius: '70px',
                                margin: '0 auto'
                            }}
                        />
                    </Grid>
                    <Grid item md={1} xs={1} sx={{ textAlign: 'right' }}>
                        <LikeButton
                            jobPostId={data.id}
                            jobPublishedAt={data.publishedAt}
                            userId={userId}
                            role={userRole}
                            totalLikes={data.likes}
                            isLiked={data.isLiked}
                            isUserAuthenticated={isUserAuthenticated}
                            refetchAppliedJobCount={props.refetchAppliedJobCount}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ maxHeight: '118px', height: '100%', mt: 1, mb: '20px' }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography variant='h5' className='small-size-16px'>
                            {truncateDetails(data.companyName, 20)}
                        </Typography>
                        <Typography variant='h3' className='small-size-18px strong' sx={{ mt: 1 }}>
                            {truncateDetails(data.jobTitle, 20)}
                        </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex' }}>
                            <StyledIcons component='img' src={Location} />
                            <Typography variant='h5' className='small-size-16px'>
                                {truncateDetails(getStringFromArray(data.states), 20)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', mt: 1 }}>
                            <StyledIcons component='img' src={Department} />
                            <Typography variant='h5' className='small-size-16px'>
                                {truncateDetails(getStringFromArray(data.departments), 20)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        label={t('buttons.moreinfo')}
                        color='primary'
                        variant='contained'
                        size='large'
                        component={Link}
                        to={'/job-details/' + data.id}
                        sx={{
                            px: '73px'
                        }}
                    />
                </Box>
            </Container>
        </>
    )
}
export default JobPostCard
