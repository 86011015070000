import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Container, Grid, Collapse } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { TableHeading } from '../../base/commonStyles'
import Loader from '../Loader'
import variables from '../../settings/_variables.scss'
import NoResultsFound from '../NoResultsFound'
import UserRestriction from '../UserRestriction'
import JobListRow from '../JobList/JobListRow'
import JobSearch from '../../components/JobSearch'

import Icons from '../Icons'
import FilterIcon from '../../assets/icons/FILTER.svg'
import LoadMoreLink from '../elements/LoadMoreLink'

const SUGGESTED_JOB_QUERY = gql`
    query SuggestedJobs(
        $afterCursor: String
        $keyword: String
        $industryIds: String
        $departmentIds: String
        $locationIds: String
        $experience: String
    ) {
        suggestedJobs(
            first: 8
            after: $afterCursor
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        ) {
            edges {
                node {
                    id
                    jobTitle
                    experienceRequired
                    candidateApplied
                    companyLogoUrl
                    otherDepartment
                    otherIndustry
                    likes
                    isLiked
                    status
                    isActive
                    createdAt
                    publishedAt
                    company {
                        id
                        companyName
                        noOfEmployees
                    }
                    departments {
                        id
                        name
                    }
                    industries {
                        id
                        name
                    }
                    states {
                        id
                        name
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const SuggestedJobList = props => {
    const { listAccess } = props
    const location = useLocation()
    const [listLoader, setListLoader] = useState(false)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [filterEnable, setFilterEnable] = useState(false)
    const state = location.state
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null
    const { loading, error, data, fetchMore, refetch } = useQuery(SUGGESTED_JOB_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: '',
            ...state
        },
        onCompleted(data) {
            setFilterEnable(false)
            setListLoader(false)
        },
        onError(error) {}
    })
    const navigate = useNavigate()

    window.addEventListener('beforeunload', function (event) {
        if (location.pathname === '/suggested-jobs') navigate(location.pathname, { replace: true })
    })

    if (loading) return <Loader />

    const jobsData = data.suggestedJobs.edges.map(edge => edge.node)
    const pageInfo = data.suggestedJobs.pageInfo

    const jobListHeader = () => {
        return (
            <Container
                maxWidth={false}
                disableGutters={true}
                sx={{
                    marginBottom: '38px',
                    width: 'auto',
                    height: '60px'
                }}
            >
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                        marginBottom: '50px'
                    }}
                >
                    <Grid container>
                        <Box
                            sx={{
                                // position: 'absolute',
                                position: 'relative',

                                left: '-39px',
                                paddingTop: '4px'
                            }}
                        ></Box>

                        <Grid item md={4}>
                            <TableHeading
                                sx={{ fontSize: responsiveBreakpoint === 'mob' ? '26px' : '30px' }}
                            >
                                Suggestions for you
                            </TableHeading>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        )
    }

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                padding: {
                    xs: '0px 30px 30px 30px',
                    sm: '0px 80px 30px 80px',
                    md: '0px 80px 30px 80px',
                    lg: '0px 80px 30px 80px',
                    xl: '0px 80px 30px 80px'
                }
            }}
        >
            <Grid container>
                <Grid item md={12} xs={12} sm={12}>
                    <Box
                        sx={{
                            display: responsiveBreakpoint === 'mob' ? 'flex' : 'inline',
                            position:
                                !['admin', 'employer'].includes(userRole) &&
                                responsiveBreakpoint === 'mob'
                                    ? 'relative'
                                    : null
                        }}
                    >
                        {jobListHeader()}
                        {!['admin', 'employer'].includes(userRole) &&
                        responsiveBreakpoint === 'mob' ? (
                            <Box onClick={() => setFilterEnable(!filterEnable)}>
                                <Box sx={{ paddingTop: '12px', position: 'absolute', right: 0 }}>
                                    <Icons src={FilterIcon} />
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                    {responsiveBreakpoint === 'mob' ? (
                        <Collapse in={filterEnable}>
                            <Box>
                                <JobSearch redirectTo={'/suggested-jobs'} />
                            </Box>
                        </Collapse>
                    ) : null}
                    {jobsData.length > 0 ? (
                        jobsData.map(data => (
                            <JobListRow
                                data={data}
                                refetch={refetch}
                                listAccess={listAccess}
                                userRole={userRole}
                            />
                        ))
                    ) : (
                        <NoResultsFound content='No Jobs found with the selected criteria' />
                    )}
                    {isUserAuthenticated && pageInfo.hasNextPage ? (
                        listLoader ? (
                            <Box
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            </Box>
                        ) : (
                            <LoadMoreLink
                                onClick={() => {
                                    if (pageInfo.hasNextPage) {
                                        setListLoader(true)
                                        fetchMore({
                                            variables: {
                                                afterCursor: pageInfo.endCursor
                                                    ? pageInfo.endCursor
                                                    : ''
                                            }
                                        })
                                    }
                                }}
                            />
                        )
                    ) : null}
                </Grid>
                {<Grid item md={3}></Grid>}
            </Grid>
            {isUserAuthenticated ? null : <UserRestriction />}
        </Container>
    )
}

export default SuggestedJobList
