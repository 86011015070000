import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Container } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DashboardJobsContainer from '../../views/Dashboard/DashboardJobsContainer'
import variables from '../../settings/_variables.scss'
import MoreJobOffers from '../MoreJobOffers'
import LoadMoreLink from '../elements/LoadMoreLink'

const GET_APPLIED_JOBS_QUERY = gql`
    query GetCandidateAppliedJobs($after: String, $id: ID!) {
        getCandidateAppliedJob(first: 3, after: $after, id: $id) {
            edges {
                node {
                    id
                    jobTitle
                    companyName
                    experienceRequired
                    companyLogoUrl
                    otherDepartment
                    otherIndustry
                    status
                    isActive
                    balancedByBunton
                    hasMotivationalLetter
                    isMotivationalRequired
                    hasResume
                    appliedJobDetails
                    industries {
                        id
                        name
                    }
                    states {
                        id
                        name
                    }
                    departments {
                        id
                        name
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const CandidateDashboardAppliedList = props => {
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } =
        useSelector(state => state.login)
    const [listLoader, setListLoader] = useState(false)
    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [resumeUploadedInOpenBox, setResumeUploadedInOpenBox] = useState(false);
    const [temproary, setTemproary] = useState(false);
    const { loading, data, fetchMore, refetch} = useQuery(GET_APPLIED_JOBS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: loginCheckAuthDetails.id ? loginCheckAuthDetails.id : loginDetails.id
        },
        onCompleted() {
            setListLoader(false)
        }
    })

    const isResumeUploaded = (value) => {
        if(resumeUploadedInOpenBox){
            setResumeUploaded(true)
        }
    }

    const reloadData = () => {
        refetch();
    }

    const isPreviouslySet = (value) => {
        if(value){
            setResumeUploadedInOpenBox(value)
        }
    }

    if (loading)
		return (
			<Box
				sx={{
					textAlign: 'center'
				}}
			>
				<CircularProgress
					sx={{
						color: variables.BLUE
					}}
				/>
			</Box>
		)
    const appliedJobs = data && data.getCandidateAppliedJob.edges.map(edge => edge.node)

    const pageInfo = data && data.getCandidateAppliedJob.pageInfo
    return (
        <Container
            maxWidth='xl'
            disableGutters   
        >
            <Box sx={{mb: 5}}>
                {appliedJobs && appliedJobs.length > 0 ? (
                    appliedJobs.map(job => <DashboardJobsContainer jobData={job} resumeUploaded={resumeUploaded} isResumeUploaded={isResumeUploaded} isPreviouslySet={isPreviouslySet} reloadData={reloadData} />)
                ) : (
                    <MoreJobOffers />
                )}
                {isUserAuthenticated && pageInfo && pageInfo.hasNextPage ? (
                    listLoader ? (
                        <Box
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <CircularProgress
                                size={20}
                                sx={{
                                    height: '20px',
                                    width: '20px',
                                    color: variables.BLUE
                                }}
                            />
                        </Box>
                    ) : (
                        <LoadMoreLink
                            onClick={() => {
                                if (pageInfo.hasNextPage) {
                                    setListLoader(true)
                                    fetchMore({
                                        variables: {
                                            after: pageInfo.endCursor ? pageInfo.endCursor : ''
                                        }
                                    })
                                }
                            }}
                        />
                    )
                ) : null}
            </Box>
        </Container>
    )
}

export default CandidateDashboardAppliedList
