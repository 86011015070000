import { Box, CircularProgress, Container, Grid, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { StyledIcons, StyledListRow } from '../../base/commonStyles'
import PageMetaTags from '../../base/PageMetaTags'
import variables from '../../settings/_variables.scss'
import LocationIcon from '../../assets/icons/LOCATION.svg'
import ExperienceIcon from '../../assets/icons/EXPERIENCE.svg'
import IndustryIcon from '../../assets/icons/INDUSTRY.svg'
import DepartmentIcon from '../../assets/icons/DEPARTMENT.svg'

import Layout from '../../template/Layout'

import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import NoResultsFound from '../../components/NoResultsFound'
import { gql, useQuery } from '@apollo/client'

import CandidateListRow from './CandidateListRow'
import { useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import {
    getExperienceLabel,
    getStringFromArray,
    truncateDetails
} from '../../utils/commonFunctions'
import BuntonRecommendations from '../../components/BuntonRecommendations'
import DefaultCompanyLogo from '../../components/molecules/DefaultCompanyLogo'
import LoadMoreLink from '../../components/elements/LoadMoreLink'

const StyledIconTextGrid = styled(Box)`
    display: flex;
`

const JOB_APPLICATIONS_QUERY = gql`
    query JobApplication($id: ID!) {
        jobPost(id: $id) {
            id
            jobTitle
            experienceRequired
            noOfCandidatesApplied
            companyLogoUrl
            otherDepartment
            otherIndustry
            appliedJobDetails
            industries {
                id
                name
            }
            departments {
                id
                name
            }
            states {
                id
                name
            }
        }
    }
`

const GET_APPLIED_CANDIDATES_QUERY = gql`
    query GetAppliedCandidates($id: ID!, $afterCursor: String) {
        appliedCandidatesList(id: $id, after: $afterCursor, first: 8) {
            edges {
                node {
                    id
                    name
                    userId
                    title
                    otherIndustry
                    industries {
                        id
                        name
                    }
                    experience
                    user {
                        profileImage
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`

const AppliedCandidate = props => {
    let urlParams = useParams()
    const { loginDetails, loginCheckAuthDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )

    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { loading, data } = useQuery(JOB_APPLICATIONS_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: urlParams.jobId
        },
        onCompleted() {
            setListLoader(false)
        }
    })

    const {
        loading: listingLoading,
        data: listingData,
        refetch,
        fetchMore
    } = useQuery(GET_APPLIED_CANDIDATES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id: urlParams.jobId,
            after: ''
        },
        onCompleted() {
            setListLoader(false)
        }
    })

    const [listLoader, setListLoader] = useState(false)

    if (loading) return <Loader />

    const jobsData = data.jobPost
    const appliedData =
        listingData && listingData.appliedCandidatesList.edges.map(edge => edge.node)
    const pageInfo = listingData && listingData.appliedCandidatesList.pageInfo

    const updatedCandidates = (appliedData ?? []).map(candidate => {
        const appliedJobDetail = jobsData?.appliedJobDetails?.find(
          jobDetail => jobDetail.candidate_id === parseInt(candidate.id)
        );

        if (appliedJobDetail) {
          return {
            ...candidate,
            motivational_letter: appliedJobDetail.motivational_letter || null,
            is_motivational_required: appliedJobDetail.is_motivational_required ?? false,
          };
        }

        return candidate;
    });

    const appliedCandidatesData = updatedCandidates

    return (
        <Layout>
            <PageMetaTags title={'Applied Candidate(s) | Bunton'} />
            <Container
                maxWidth='xl'
                sx={{
                    mt: {
                        xs: 5,
                        md: 10
                    }
                }}
            >
                <Box
                    sx={{
                        mb: {
                            xs: 5,
                            md: 8
                        }
                    }}
                >
                    <StyledListRow
                        maxWidth='xl'
                        disableGutters
                        sx={{
                            minHeight: '92px'
                        }}
                    >
                        <Grid container spacing={2} alignItems={{
                            xs: 'center',
                            sm: 'start'
                        }}>
                            <Grid item md={1.2} sm={2} xs={4}>
                                <DefaultCompanyLogo
                                    imgSrc={jobsData.companyLogoUrl}
                                    sx={{ width: '62px', height: '62px' }}
                                />
                            </Grid>
                            <Grid item md={8.8} xs={responsiveBreakpoint !== 'mob' ? 6 : 8} sm={8}>
                                <Box>
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-18px'
                                    >
                                        {jobsData.jobTitle}
                                    </Typography>
                                </Box>
                                {responsiveBreakpoint !== 'mob' ? <Box
                                    sx={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row',
                                        }
                                    }}
                                >
                                    <StyledIconTextGrid item alignItems='center'>
                                        <StyledIcons component='img' src={LocationIcon} />
                                        <Typography variant='h5' className='small-size'>
                                            {truncateDetails(
                                                getStringFromArray(jobsData.states)
                                            )}
                                        </Typography>
                                    </StyledIconTextGrid>
                                    <Box
                                        sx={{
                                            ml: {
                                                xs: 0,
                                                md: 2
                                            }
                                        }}
                                    >
                                        <StyledIconTextGrid item alignItems='center'>
                                            <StyledIcons
                                                component='img'
                                                src={ExperienceIcon}
                                            />
                                            <Typography variant='h5' className='small-size'>
                                                {getExperienceLabel(
                                                    jobsData.experienceRequired
                                                )}
                                            </Typography>
                                        </StyledIconTextGrid>
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: {
                                                xs: 0,
                                                md: 2
                                            }
                                        }}
                                    >
                                        <StyledIconTextGrid item alignItems='center'>
                                            <StyledIcons
                                                component='img'
                                                src={DepartmentIcon}
                                            />
                                            <Typography variant='h5' className='small-size'>
                                                {truncateDetails(
                                                    getStringFromArray(
                                                        jobsData.departments,
                                                        jobsData.otherDepartment
                                                    )
                                                )}
                                            </Typography>
                                        </StyledIconTextGrid>
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: {
                                                xs: 0,
                                                md: 2
                                            }
                                        }}
                                    >
                                        <StyledIconTextGrid item alignItems='center'>
                                            <StyledIcons
                                                component='img'
                                                src={IndustryIcon}
                                            />
                                            <Typography variant='h5' className='small-size'>
                                                {truncateDetails(
                                                    getStringFromArray(
                                                        jobsData.industries,
                                                        jobsData.otherIndustry
                                                    )
                                                )}
                                            </Typography>
                                        </StyledIconTextGrid>
                                    </Box>
                                </Box> : <Box sx={{mt: 1}}>
                                    <Typography
                                    variant='h5'
                                    className='small-size-strong'
                                >
                                    {jobsData.noOfCandidatesApplied} Applied
                                </Typography>
                                    </Box>}
                            </Grid>
                            {
                                responsiveBreakpoint !== 'mob' && <Grid
                                item
                                md={2}
                                sm={2}
                                xs={3}
                                sx={{
                                    textAlign: 'center'
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    className='small-size-strong'
                                >
                                    {jobsData.noOfCandidatesApplied} Applied
                                </Typography>
                            </Grid>
                            }
                        </Grid>
                    </StyledListRow>
                </Box>

                <Grid
                    container
                    spacing={5}
                    sx={{mb: 5}}
                >
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <Box
                            sx={{
                                mb: 5
                            }}
                        >
                            <Typography variant='h2' className='modal-heading'>Applied Candidate(s)</Typography>
                        </Box>

                        {appliedCandidatesData && appliedCandidatesData.length > 0 ? (
                            appliedCandidatesData.map(data => (
                                <CandidateListRow data={data} refetch={refetch} userRole={userRole} />
                            ))
                        ) : (
                            <NoResultsFound content='No Jobs found with the selected criteria' />
                        )}
                        {isUserAuthenticated && pageInfo && pageInfo.hasNextPage ? (
                            listLoader ? (
                                <Box
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: variables.BLUE
                                        }}
                                    />
                                </Box>
                            ) : (
                                <LoadMoreLink
                                    onClick={() => {
                                        if (pageInfo && pageInfo.hasNextPage) {
                                            setListLoader(true);
                                            fetchMore({
                                                variables: {
                                                    afterCursor: pageInfo.endCursor || '',
                                                },
                                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                                    if (!fetchMoreResult || !fetchMoreResult.appliedCandidatesList) {
                                                        setListLoader(false);
                                                        return previousResult;
                                                    }

                                                    return {
                                                        appliedCandidatesList: {
                                                            edges: [
                                                                ...previousResult.appliedCandidatesList.edges,
                                                                ...fetchMoreResult.appliedCandidatesList.edges,
                                                            ],
                                                            pageInfo: fetchMoreResult.appliedCandidatesList.pageInfo,
                                                        },
                                                    };
                                                },
                                            }).finally(() => setListLoader(false));
                                        }
                                    }}
                                />

                            )
                        ) : null}
                    </Grid>
                    {responsiveBreakpoint !== 'mob' && <Grid
                        item
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <BuntonRecommendations />
                    </Grid>}
                </Grid>
            </Container>
        </Layout>
    )
}

export default AppliedCandidate
