import { ThemeProvider } from '@emotion/react';
import {
    Box,
    Checkbox,
    createTheme,
    FormControl,
    InputAdornment,
    getOffsetLeft,
    MenuItem,
    Select,
    styled,
    ListItemText
} from '@mui/material';
import React from 'react';
import DownArrow from '../../assets/icons/ARROW DOWN.svg';
import variables from '../../settings/_variables.scss';
import { truncateDetails } from '../../utils/commonFunctions';

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    zIndex: '2'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingRight: '40px !important',
                    paddingLeft: '15px',
                    fontSize: '18px',
                    color: variables.BLUE
                },
                notchedOutline: {
                    border: '3px solid !important',
                    borderColor: `${variables.CYAN} !important`,
                    borderRadius: '50px !important'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: 290,
                    maxHeight: '290px !important',
                    border: `3px solid ${variables.CYAN}`,
                    borderTop: '0',
                    boxShadow: 'none',
                    zIndex: 99
                }
            }
        }
    }
});

const StyledMenuItems = styled(MenuItem)`
    padding: 6px 30px;
    text-align: right;
    font-size: 18px;
    color: ${variables.BLUE};
`;

const MultiSelectDropDown = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const handleClose = () => {
        setAnchorEl(null);
        setDropdownOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <FormControl
                sx={{
                    ...props.formControllerSx,
                    m: 0,
                    zIndex: 1,
                    // marginTop: '0px',
                    background: variables.WHITE1,
                    borderRadius: '50px'
                }}
            >
                <Select
                    multiple
                    variant='outlined'
                    onChange={props.onChange}
                    onClose={handleClose}
                    onOpen={(e) => {
                        setAnchorEl(e.currentTarget);
                        setDropdownOpen(true);
                    }}
                    displayEmpty={true}
                    className='dropdown'
                    defaultValue={props.lab}
                    placeholder={props.placeholder}
                    value={props.value || []}
                    renderValue={(value) => {
                        if (!value || value.length === 0) {
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  {props.startAdornment && (
                                    <InputAdornment position="start">
                                      <Box sx={{ position: 'relative' }}>{props.startAdornment}</Box>
                                    </InputAdornment>
                                  )}

                                  <Box sx={{ color: 'rgb(0, 45, 89)' }}>{props.lab}</Box>
                                </Box>
                            );
                        }
                        return (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {value.map((selectedValue, index) => {
                                    if (index === 0) {
                                        const item = props.options?.find(
                                            (val) => val.id === selectedValue || val.value === selectedValue
                                        );
                                        return (
                                            <Box key={selectedValue} sx={{ display: 'flex', gap: 1 }}>
                                                <Box sx={{ position: 'relative', margin: '0px 5px 0px 10px' }}>
                                                    {props.startAdornment}
                                                </Box>
                                                {item
                                                    ? truncateDetails(item.name || item.label, 19)
                                                    : selectedValue}
                                            </Box>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        );
                    }}
                    IconComponent={() => (
                        <InputAdornment position='end'>
                            <Box sx={{ position: 'relative', left: '-23px' }}>
                                <Box
                                    component='img'
                                    src={DownArrow}
                                    className={dropdownOpen ? 'dropdown--icon_open' : ''}
                                />{' '}
                            </Box>
                        </InputAdornment>
                    )}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Box sx={{ position: 'relative' }}>
                                    <Box component='img' src={DownArrow} />{' '}
                                </Box>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Box sx={{ position: 'relative' }}>{props.startAdornment}</Box>
                            </InputAdornment>
                        )
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            // vertical: getOffsetLeft(anchorEl) - 2,
                            horizontal: getOffsetLeft(anchorEl) + 170
                        },
                        // transformOrigin: {
                        //     vertical: 'top',
                        //     horizontal: 'right'
                        // },
                        autoFocus: false, // Prevent default focus behavior
                        onEntered: (event) => {
                            const container = event.target;
                            if (container) {
                                container.scrollTop = 0; // Scroll to top when menu opens
                            }
                        },
                        PaperProps: {
                            sx: {
                                maxWidth: {
                                    md: 200,
                                    lg: 265,
                                    xl: 290
                                },
                                width: {
                                    md: 200,
                                    lg: 265,
                                    xl: 290
                                },
                                borderRadius: '30px',
                                // borderTopLeftRadius: '0px',
                                // borderTopRightRadius: '0px',

                                border: `3px solid ${variables.CYAN}`,
                                // borderTop: '0px',
                                backgroundColor: variables.WHITE1,
                                paddingTop: '20px',
                                paddingRight: '8px !important',
                                paddingBottom: '20px',
                            }
                        },
                        MenuListProps: {
                            sx: {
                                maxHeight: '250px !important',
                                overflow: 'hidden',
                                overflowY: 'scroll'
                            }
                        }
                    }}
                >   <StyledMenuItems
                        disabled
                        value=''
                        sx={{
                            width: '290px',
                            opacity: '1 !important',
                            color: 'rgba(0, 0, 0, 0.16)',
                            borderRadius: '30px',
                            backgroundColor: variables.WHITE1,
                            padding: '14px 30px'
                        }}
                    >
                        {props.placeholder}
                        <InputAdornment
                            position='end'
                            sx={{
                                right: {
                                    md: '50px',
                                    lg: '40px',
                                    xl: '30px'
                                },
                                position: 'relative',
                                right: '-150px'
                            }}
                        >
                            <Box>
                                <Box
                                    component='img'
                                    src={DownArrow}
                                    className={dropdownOpen ? 'dropdown--icon_open' : ''}
                                    sx={{ position: 'relative', left: '-23px' }}
                                />{' '}
                            </Box>
                        </InputAdornment>
                    </StyledMenuItems>
                    {props?.options.map((option) => (
                        <StyledMenuItems key={option.id || option.value} value={option.id || option.value} >
                            <Checkbox checked={Array.isArray(props.value) && props.value.includes(option.id || option.value)} sx={{ p: 0 }}/>
                            <ListItemText primary={option.name || option.label} sx={{ ml: 1, flex: 'none' }}/>
                        </StyledMenuItems>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
};

export default MultiSelectDropDown;